import styled, { css } from 'styled-components'
import { Button, MobileStepper } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';

// export const OnboardingWrapper = styled.div`
//   flex: 11;
//   padding: 0px 0 70px 0;
//   height: auto;
//   display: flex;
//   flex-direction: column;

//   ${({ noBottomPadding }) => noBottomPadding && css`
//     padding-bottom: 0;
//   `}

//   ${({ errMsg }) => errMsg && css`
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 20px 0;
//     height: 100%;
//   `}

//   ${({ imageFilters }) => imageFilters && css`
//     min-height: 100%;
//   `}

//   @media (max-width: 500px) {
//     width: 978px;
//   }

//   .ui.fluid.input>input::selection {
//     color: black; !important;
//     background: #ACCEF7 !important;
//   }
// `

export const OnboardingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  ${({ cstmheight }) => cstmheight && css`
  height: ${cstmheight}!important;
  `}
  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
width: 500px;
height: 100%;
background: linear-gradient(90deg, #F2F2F2, ${appColors.modalBackgroundColor});;
position: relative;

${({ cstmheight }) => cstmheight && css`
  height: 100vh!important;
  `}

@media (max-width: ${maximumTabletWidth}px) {
  width: 100%;
}

`

export const GameContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 95%;
`

export const BannerWrapper = styled(motion.div)`
  height: 70%;
  position: relative;
  ${({ cstmheight }) => cstmheight && css`
  height: ${cstmheight};
  `}

  ${({ background }) => background && css`
  background: url(${background});
  `}
  
  ${({ backgroundposition }) => backgroundposition && css`
    background-position: ${backgroundposition};  
  `}
  background-repeat: no-repeat;
  background-size: cover;
  transition: height 0.1s ease-in-out!important; /* Fallback for browsers without framer-motion support */
`
export const BottomCWrapper = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${({ cstmheight }) => cstmheight && css`
    height: ${cstmheight}!important;
  `}
  
`

export const Heading = styled.div`
  padding: 4vh 4vw 2vh 4vw;
`

export const HeadingText1 = styled.div`
  font-family: poppins700;
  
  ${({ fontSize, fontFamily, fontColor }) => fontSize && css`
    font-size: ${fontSize ? fontSize : 12+'px'};
    color: ${fontColor ? fontColor : appColors.headingTextColor};
    font-family: ${fontFamily && fontFamily};
  `}
  ${({ padding }) => padding && css`
    padding: ${padding};
  `}
`

export const Description = styled.div`
  font-family: poppins500;
  display: flex;
  justify-content: space-between;
  padding: 0 4vw 4vh 4vw;
`

export const NextBtn = styled(motion(Button))`
  text-transform: none!important;
  min-width: auto!important;
  border-radius: 8px!important;
  padding: 0!important;
  box-shadow: none!important;

  ${({ boxshadow}) => boxshadow && css`
    box-shadow: -3px 3px 15px 1px #5E5E5EB2!important;
  `}
  ${({ boxshadowactionbtn}) => boxshadowactionbtn && css`
    box-shadow: 0px 6px 10px 0px #5E5E5EB2!important;
  `}
  ${({ borderradius }) => borderradius && css`
  border-radius: ${borderradius}!important;
  `}
  ${({ height, width, backgroundcolor, initial }) => css`
    height: ${height};
    width: ${width};
    background: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) => border && css`
    border: ${border}!important;
    border-color: ${borderColor}!important;
    border-style: ${borderStyle && borderStyle}!important;
  `}

  ${({ boxshadow }) => boxshadow && css`
    box-shadow: ${boxshadow}!important;
  `}
  ${({ cursor }) => cursor && css`
    cursor: ${cursor}!important;
  `}

  ${({ positionabsolute }) => positionabsolute && css`
    position: absolute!important;
    
  `}
`

export const MoveBtn = styled(motion.div)`
  display: flex;
  align-items: center;
  ${({ contentCenter }) => contentCenter && css`
    justify-content: center;
  `}
  ${({ height }) => height && css`
    height: ${height};
  `}
  ${({ gap }) => gap && css`
    gap: ${gap};
  `}
  ${({ positionabsolute }) => positionabsolute && css`
    position: absolute;
    justify-content: center;
    width: 100%;
    z-index: 9;
  `}
`

export const Stepper = styled(MobileStepper)`

.MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  ${({ activestepstyle }) => css`
    background-color: ${activestepstyle.color}!important;
    width: ${activestepstyle.width}!important;
    height: ${activestepstyle.height}!important;
  `}
  border-radius: 50px!important;
}

.MuiMobileStepper-dot {
  ${({ stepstyle }) => stepstyle && css`
    background-color: ${stepstyle.color}!important;
    width: ${stepstyle.width}!important;
    height: ${stepstyle.height}!important;
  `}
}

`

export const StepContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
  ${({ gap }) => gap && css`
    gap: ${gap};
  `}
  ${({ cstmHeight }) => cstmHeight && css`
    height: ${cstmHeight};
  `}
  ${({ contentstarttop }) => contentstarttop && css`
  justify-content: ${contentstarttop};
  `}
  ${({ cstmpaddingtop }) => cstmpaddingtop && css`
  padding-top: ${cstmpaddingtop};
  `}
  ${({ positionabsolute }) => positionabsolute && css`
  position: absolute;
  `}
`

export const TextBtn = styled.span`
  font-family: poppins700;
  ${({ fontFamily }) => fontFamily && css`
  font-family: ${fontFamily}!important;
  `}
  ${({ color, fontSize }) => css`
    color: ${color};
    font-size: ${fontSize};
  `}
  ${({ paddingtopcstm }) => paddingtopcstm && css`
    padding-top: ${paddingtopcstm};
  `}  
`

export const LeftContent = styled.div`
`

export const RightContent = styled.div`
`

export const StepContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
    justify-content: space-around;
  `}
  ${({ aligncenter }) => aligncenter && css`
    align-items: ${aligncenter};
  `}
  ${({ positionrelative }) => positionrelative && css`
    position: relative;
  `}
  ${({ zindex }) => zindex && css`
    z-index: 99;
  `}
`

export const Step1Top =  styled(motion.div)`
  display: flex;
  justify-content: center;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
  `}
  ${({ alignBottom }) => alignBottom && css`
    display: flex;
    align-items: end;
  `}
  ${({ positionrelative }) => positionrelative && css`
    position: ${positionrelative};
  `}

  ${({ height }) => height && css`
    min-height: ${height};
  `}
`

export const Step1Bottom = styled(motion.div)`
display: flex;
${({ gap }) => css`
  gap: ${gap};
`}
justify-content: end;
padding-right: 30px;
`

export const Step2Bottom = styled.div`
display: flex;
${({ gap }) => css`
  gap: ${gap};
`}
${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
  `}
`

export const LoadImg = styled(motion.img)`
  ${({ height, width, transform }) => css`
  height: ${height};
  width: ${width};
  transform: ${transform};
  // transition: transform 0.3s ease;
  `}
`

export const AttemptScoreContainer = styled(motion.div)`
  ${({ cstmHeight }) => cstmHeight && css`
    max-height: ${cstmHeight};
    overflow: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none; 
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display: none; 
    }
  `}
  ${({ padding }) => padding && css`
    padding: ${padding};
  `}

  ${({ width }) => width && css`
  width: ${width};
  `}
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const AttemptValContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const AttemptLabel = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins700;
  ${({ fontfamily }) => fontfamily && css`
  font-family: ${fontfamily};
  `}
  padding-left: ${screenWidth > maximumTabletWidth ? "45px" : (45 /screenWidth * 100)+"vw"};
`

export const AttemptValue = styled.div`
  width: 15%; 
  display: flex; 
  justify-content: end;
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins500;
`

export const GameBtnContainer = styled.div`
  justify-content: center;
  width: 100%;
  display: flex; 
  align-items: center; 
`

export const GenerateWord =  styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${screenWidth > maximumTabletWidth ? "22px" : (22 /screenWidth * 100)+"vw"};
  line-height: ${screenWidth > maximumTabletWidth ? "22px" : (22 /screenWidth * 100)+"vw"};
  padding-top: ${screenWidth > maximumTabletWidth ? "25px" : (25 /screenWidth * 100)+"vh"};
  ${({ cstmtoppadding }) => cstmtoppadding && css`
    padding-top: ${cstmtoppadding};
  `}
  font-family: poppins700;
  color: ${appColors.greyTextColor};
  ${({ fontFamily }) => fontFamily && css`
  font-family: ${fontFamily};
  `}
`

export const TopOptions =  styled(motion.div)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 9;
  padding: ${screenWidth > maximumTabletWidth ? "0px 0 30px 0" : `${(0 /screenWidth * 100)}vh 0 ${(20 /screenWidth * 100)}vh 0`};
  ${({ cstmwidth }) => css`
    width: ${cstmwidth};
  `}
`

export const TopOptionsLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
`

export const TopOptionsRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${screenWidth > maximumTabletWidth ? "15px" : (15 /screenWidth * 100)+"vw"};
`

export const LeftOptionsFirst = styled.div`
    position: relative;
  height: ${screenWidth > maximumTabletWidth ? "23px" : (23 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "23px" : (23 /screenWidth * 100)+"vw"};
  // border: 2px solid #5E5E5E;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &::before {
    content: "";
    position: absolute;
    top: -1.25px;
    left: -1.25px;
    right: -1.25px;
    bottom: -1.25px;
    border: 2.8px solid #5E5E5E;
    border-radius: 4px; /* Match the border radius of the parent element */
    pointer-events: none;
  }
`
export const OptionsFirstImg = styled(motion.img)`
  height: ${screenWidth > maximumTabletWidth ? "14px" : (14 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "14px" : (14 /screenWidth * 100)+"vw"};
  // position: absolute;
`

export const NameContainer = styled.div`
font-family: poppins600;
fontSize: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
color: ${appColors.greyTextColor};
`

export const RightOptionsFirst = styled.div`
  ${({ height, width }) => css`
  height: ${height};
  width: ${width};
  `}
`
export const RightOptionsFirstImg = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
`

export const AddStep1Container = styled(motion.div)`
  display: flex;
  align-items: end;
`

export const DashedLinesContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth ? "3px" : (3 /screenWidth * 100)+"vw"};
`

export const DashedLine = styled.div`
  width: ${screenWidth > maximumTabletWidth ? "2px" : (2 /screenWidth * 100)+"vw"};
  ${({ height }) => height && css`
    height: ${height};
  `}
  ${({ background }) => background && css`
    background-color: ${background};
  `}
`

export const DashedAddImg = styled(motion.img)`
  cursor: pointer;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gap}) => css`
    gap: ${gap};
  `};
`

export const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${screenWidth > maximumTabletWidth ? "65px" : (65 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "65px" : (65 /screenWidth * 100)+"vw"};
  ${({ bgcolor }) => css`
    background-color: ${bgcolor};
  `};
  border-radius: 50%;
`

export const StatusMessage = styled.div`
font-family: poppins400;
font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
color: ${appColors.greyTextColor};
`

export const RemoveIcon = styled.div`
  height: ${screenWidth > maximumTabletWidth ? "18px" : (18 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "18px" : (18 /screenWidth * 100)+"vw"};
  border-radius: 50%;
  background: ${appColors.actionIconBgColor};
  left: ${screenWidth > maximumTabletWidth ? "-9px" : (-9 /screenWidth * 100)+"vw"};
  position: absolute;
  z-index: 99;
  top: ${screenWidth > maximumTabletWidth ? "-9px" : (-9 /screenWidth * 100)+"vw"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const RemoveChilcIcon = styled.div`

width: ${screenWidth > maximumTabletWidth ? "8px" : (8 /screenWidth * 100)+"vw"};
border-bottom: 2px solid #000000;

`