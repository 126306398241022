import styled, { css } from 'styled-components'
import { Box, Modal } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';

export const ModalContainer = styled(Modal)`
    .MuiBackdrop-root.MuiModal-backdrop {
        // opacity: 0.5!important;
        background-color: rgba(171, 171, 171, 0.5)!important;
    }
`

export const BoxContainer = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px!important;
    background-color: ${appColors.modalBackgroundColor};
    padding: ${screenWidth > maximumTabletWidth ? "10px" : (10 /screenWidth * 100)+"vw"};
    outline: none!important;
    @media (max-width: ${maximumTabletWidth}px) {
        width: 85vw!important;
    }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  ${({ gap }) => css`
    gap: ${gap};
  `}  
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}  
`

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => gap && css`
    gap: ${gap};
  `} 
  ${({ postionrelative }) => postionrelative && css`
    position: ${postionrelative};
  `} 
  ${({ textalign }) => textalign && css`
    align-items: ${textalign};
  `} 
`

export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}
  ${({ textalign }) => textalign && css`
    text-align: ${textalign};
  `}
`

export const CrossImg = styled.img`
  position: absolute;
  right: 0;
  padding-right: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  height: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  cursor: pointer;
`

export const StarImg = styled.img`
  height: ${screenWidth > maximumTabletWidth ? "26px" : (26 / screenWidth * 100) + "vw"};
  width: ${screenWidth > maximumTabletWidth ? "27px" : (27 / screenWidth * 100) + "vw"};
`

export const StarContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"};
`

export const StarRowContainer = styled.div`
    width: 100%;
    display: flex;
    gap: ${screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"};
`

export const StarRowLabel = styled.div`
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: ${screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth * 100) + "vw"};
    font-family: poppins400;
    color: ${appColors.greyTextColor};
`

export const StarRowValue = styled.div`
    width: 55%;
    display: flex;
    gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"};
`