import React, { useEffect, useLayoutEffect } from 'react'

import BannerImg from '../../components/bannerImg'
import {
  ContentWrapper,
  OnboardingWrapper,
  BannerWrapper,
  BottomCWrapper,
  Description,
  Heading,
  HeadingText1,
  LeftContent,
  RightContent,
  NextBtn,
  MoveBtn
} from './styles'
import { screenWidth, maximumTabletWidth } from '../../utils'
import DotsMobileStepper from './steper'
import { appImages } from '../../themes/imageConstant';
import { appColors } from '../../themes/colorConst'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import { AnimatePresence, useAnimation, motion } from 'framer-motion'
import Step4 from './step4'
import Step5 from './step5'
import Step6 from './step6'
import Step7 from './step7'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { completeOnboardingInitiate, getWordInitiate } from '../../redux/actions/game'
import { browserName, deviceType, osName } from 'react-device-detect'
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

const opacityDuration = 1

const Onboarding = () => {

  // alert(osName)

  const gaEventTracker = useAnalyticsEventTracker('User Events');

  const bottomContent = useAnimation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = React.useState(0);
  const [bannerImage, setBannerImage] = React.useState(appImages.bannerImg);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [endX, setEndX] = React.useState(0);
  const [endY, setEndY] = React.useState(0);

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', `black`);
    }
  }, []);

  let tempstartX = 0
    const handleTouchStart = (e) => {
      console.log("this is touchstart = ", e.touches[0].clientX)
      tempstartX = e.touches[0].clientX
      // setStartX(e.touches[0].clientX);
    };

  const handleTouchEnd = (e) => {
      
    const touchEndX = e.changedTouches[0].clientX;
    console.log("this is touch end = ", touchEndX)
    console.log("this is touch end check = ", tempstartX - touchEndX)
    if (tempstartX - touchEndX > 50) {
      console.log("this is touchend 1 = ", e.changedTouches[0].clientX)
      console.log("this is touchend activeStep = ", activeStep)
      // Swipe left
      setActiveStep((prev) => {
        if (prev < steps.length - 1) {
          console.log("this is active step = ", prev)
        return  prev + 1
        } else {
          dispatch(completeOnboardingInitiate({ isInitiate: true }))
          return prev
        }   
    });

      
    }
    // if (tempstartX - touchEndX < -50) {
    //   console.log("this is touchend 2 = ", e.changedTouches[0].clientX)
    //   // Swipe right
    //   setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
    // }
  }

  useEffect(() => {
    

    

    const contentContainer = document.getElementById("contentContainer");
    contentContainer.addEventListener('touchstart', handleTouchStart);
    // contentContainer.addEventListener('touchmove', handleTouchMove);
    contentContainer.addEventListener('touchend', handleTouchEnd);

    return () => {
      contentContainer.removeEventListener('touchstart', handleTouchStart);
      // contentContainer.removeEventListener('touchmove', handleTouchMove);
      contentContainer.addEventListener('touchend', handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    const animateStep2 = async () => {
      await bottomContent.start({
        y: 1,
        transition: { duration: 0, delay: opacityDuration },
      })
      // Add some delay before starting the second animation
      await new Promise((resolve) => setTimeout(resolve, opacityDuration * 1000));
      await bottomContent.start({
        y: 0,
        transition: { duration: 0 },
      })
      await new Promise((resolve) => setTimeout(resolve, 100));
      setTimeout(animateStep2, 0);
    }

    // animateStep2()

    return () => {
      bottomContent.stop();
    };
  }, [])

  // useEffect(() => {
  //   switch (activeStep) {
  //     case 0:
  //       setBannerImage(appImages.bannerImg)
  //       break;
  //     case 1:
  //       setBannerImage(appImages.bannerImg2)
  //       break;
  //     case 2:
  //       setBannerImage(appImages.bannerImg3)
  //       break;
  //     case 3:
  //       setBannerImage(appImages.bannerImg4)
  //       break;
  //     case 4:
  //       setBannerImage(appImages.bannerImg5)
  //       break;
  //     case 5:
  //       setBannerImage(appImages.bannerImg6)
  //       break;
  //     case 6:
  //       setBannerImage(appImages.bannerImg7)
  //       break;
  //     default:
  //       break;
  //   }

  // }, [activeStep])

  const steps = [
    <Step1 />,
    <Step2 />,
    <Step3 />,
    <Step4 />,
    <Step5 />,
    <Step6 />,
    <Step7 />,
  ];

  const headingContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Heading>
            <HeadingText1
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "28px"
                  : (28 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins700"}
            >
              Welcome to Change It
            </HeadingText1>
            <HeadingText1
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "16px"
                  : (16 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins500"}
              padding={"8px 0 0 0"}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Change It to chart your path to victory.
            </HeadingText1>
            <HeadingText1
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "16px"
                  : (16 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins500"}
              padding={"8px 0 0 0"}
              fontColor={`${appColors.headingTextColor}`}
              marginTop={"-5px"}
              opacity={'0.7'}
            >
              Now let’s teach you how to play.
            </HeadingText1>
          </Heading>
        );
      case 1:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              Get from Starting Word to Target Word
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Plot your path to the target word by changing the previous word using 1 of the 4 Change It actions.
            </HeadingText1>
          </Heading>
        )
      case 2:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppinsItalic300'}
            >
              Action 1
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              Add a Letter
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Add a new letter in any position to create a new word. Any new words must be part of the English dictionary.
            </HeadingText1>
          </Heading>
        )
      case 3:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppinsItalic300'}
            >
              Action 2
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              Remove a Letter
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Remove any letter to create a new word.
            </HeadingText1>
          </Heading>
        )
      case 4:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppinsItalic300'}
            >
              Action 3
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              Change a Letter
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Change any letter to create a new word.
            </HeadingText1>
          </Heading>
        )
      case 5:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppinsItalic300'}
            >
              Action 4
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              Mix Up the letters
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              Mix up the letters in any order to create a new word.
            </HeadingText1>
          </Heading>
        )
      case 6:
        return (
          <Heading padding={`${screenWidth > maximumTabletWidth ? "20px 25px 12px 25px" : (20 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw ${(12 / screenWidth * 100)}vw ${(25 / screenWidth * 100)}vw`}>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "28px" : (28 / screenWidth * 100) + "vw"}
              fontFamily={'poppins700'}
            >
              You’re ready to Change It!
            </HeadingText1>
            <HeadingText1
              fontSize={screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"}
              fontFamily={'poppins500'}
              padding={'8px 0 0 0'}
              fontColor={`${appColors.headingTextColor}`}
              opacity={'0.7'}
            >
              The aim is to get to the target word in as few changes as possible. See if you can Change It in less than your friends!
            </HeadingText1>
          </Heading>
        )
      default:
        return (
          <Heading>

          </Heading>
        )
    }
  }

  return (
    <OnboardingWrapper
      cstmheight={
        browserName == "Mobile Safari" 
        ? 
        "89vh" 
        :
        (browserName == "Chrome" && deviceType == "mobile")
        ? "87vh"
        :
        "100vh"
      }
    >
      {/* <MoveBtn
        key="componentB"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0, }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.5, repeat: 2 }}
      > */}
      <ContentWrapper id={"contentContainer"}>
      
        <BannerWrapper>
        <BannerImg image={bannerImage} />
          <AnimatePresence custom={activeStep} exit={{ opacity: 0 }}>
         
            <motion.div
              style={{ height: '100%', position: 'absolute', width: '100%', top: 0 }}
              key={activeStep}
              custom={activeStep}
              variants={{
                enter: (direction) => {
                  if (activeStep === 0) {

                    return { opacity: 1 };
                  } else {
                    return {
                      x: direction > 0 ? '100%' : '-100%',
                      opacity: 1,
                    };
                  }
                },
                center: {
                  x: 0,
                  opacity: 1,
                },
                exit: (direction) => {
                  return {
                    x: direction < 0 ? '100%' : '-100%',
                    // rotate: direction < 0 ? '10deg' : '10deg',
                    opacity: 1,
                  };
                },
              }}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                type: 'spring',
                stiffness: 70,
                damping: 20,
                ease: 'linear',
                duration: 1,
              }}
            >
            
              {steps[activeStep]}
            </motion.div>
          </AnimatePresence>
        </BannerWrapper>
        <MoveBtn
          // initial={{ y: 0 }}
          animate={activeStep == 1 && bottomContent}
          height={'40%'}
        >
          <BottomCWrapper cstmheight={"100%"}>
            <div>
              <AnimatePresence custom={activeStep} exit={{ opacity: 0 }}>
                <motion.div
                  style={{ position: 'absolute' }}
                  key={activeStep}
                  custom={activeStep}
                  variants={{
                    enter: (direction) => {
                      if (activeStep === 0) {

                        return { opacity: 1 };
                      } else {
                        return {
                          x: direction > 0 ? '100%' : '-100%',
                          opacity: 1,
                        };
                      }
                    },
                    center: {
                      x: 0,
                      opacity: 1,
                    },
                    exit: (direction) => {
                      return {
                        x: direction < 0 ? '100%' : '-100%',
                        // rotate: direction < 0 ? '10deg' : '10deg',
                        opacity: 1,
                      };
                    },
                  }}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    type: 'spring',
                    stiffness: 70,
                    damping: 20,
                    ease: 'linear',
                    duration: 1,
                  }}
                >
                  {headingContent()}
                </motion.div>
              </AnimatePresence>
            </div>
            <Description>
              <DotsMobileStepper
                activeStep={activeStep}
              />
              <NextBtn
                variant="contained"
                size="small"
                height={screenWidth > maximumTabletWidth ? "48px" : (48 / screenWidth * 100) + "vw"}
                width={screenWidth > maximumTabletWidth ? "48px" : (48 / screenWidth * 100) + "vw"}
                backgroundcolor={appColors.successBtnColor}
                // disabled={activeStep == 6}
                onClick={() => {
                  console.log("event called")
                  gaEventTracker('OnBoarding', "nextButtonCLicked", activeStep)
                  activeStep == 6
                    ?
                    dispatch(completeOnboardingInitiate({ isInitiate: true }))
                    // navigate('/game') 
                    :
                    setActiveStep(prev => prev + 1)
                }}
              >
                <img
                  style={{
                    height: screenWidth > maximumTabletWidth ? "16.67px" : (16.67 / screenWidth * 100) + "vw",
                    width: screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw",

                  }}
                  src={appImages.nextArrow}
                />
              </NextBtn>
            </Description>
          </BottomCWrapper>
        </MoveBtn>
      </ContentWrapper>
      {/* </MoveBtn> */}
    </OnboardingWrapper>
  )
}

export default Onboarding
