import * as React from 'react';
import { MobileStepper, Button, useTheme } from '@mui/material';
import { Stepper } from './styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';

const DotsMobileStepper = (props) => {

  const {
    activeStep
  } = props

  const stepperBackgroundColor = {
    0: appColors.step1Color,
    1: appColors.step2Color,
    2: appColors.step3Color,
    3: appColors.step4Color,
    4: appColors.step5Color,
    5: appColors.step6Color,
    6: appColors.step7Color,
  }

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  return (

    <Stepper
      variant="dots"
      steps={7}
      position="static"
      activeStep={activeStep}
      sx={{ flexGrow: 1 }}
      activestepstyle={{
        color: stepperBackgroundColor[0],
        width: screenWidth > maximumTabletWidth ? "43px" : (43 / screenWidth * 100) + "vw",
        height: screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw",
      }}
      stepstyle={{
        color: `${stepperBackgroundColor[0]}40`,
        width: screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw",
        height: screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw",
      }}
    />

  )
}

export default React.memo(DotsMobileStepper);