import styled, { css } from "styled-components";
import { Button, MobileStepper } from "@mui/material";

import { maximumTabletWidth, screenWidth } from "../../utils/index";
import { appColors } from "../../themes/colorConst";
import { motion } from "framer-motion";
import { appImages } from "../../themes/imageConstant";

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  ${({ postionrelative }) =>
    postionrelative &&
    css`
      position: ${postionrelative};
    `}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmpadding }) =>
    cstmpadding &&
    css`
      padding: ${cstmpadding};
    `}
`;

export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) =>
    cstmpadding &&
    css`
      padding: ${cstmpadding};
    `}
  ${({ textalign }) =>
    textalign &&
    css`
      text-align: ${textalign};
    `}
`;

export const ScoreSpan = styled.span`
font-size: ${screenWidth > maximumTabletWidth
  ? "20px"
  : (20 / screenWidth) * 100 + "vw"};
  font-family: poppins700;

`

export const PuzzleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth
    ? "5px"
    : (5 / screenWidth) * 100 + "vw"};
`;

export const BottomBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth
    ? "5px"
    : (5 / screenWidth) * 100 + "vw"};
`;

export const CrossImg = styled.img`
  position: absolute;
  right: 0;
  padding-right: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  height: ${screenWidth > maximumTabletWidth
    ? "24px"
    : (24 / screenWidth) * 100 + "vw"};
  width: ${screenWidth > maximumTabletWidth
    ? "24px"
    : (24 / screenWidth) * 100 + "vw"};
  cursor: pointer;
`;
export const TextBtn = styled.span`
  font-family: poppins700;
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily}!important;
    `}
  ${({ color, fontSize }) => css`
    color: ${color};
    font-size: ${fontSize};
  `}
`;
export const NextBtn = styled(Button)`
  text-transform: none !important;
  min-width: auto !important;
  border-radius: 8px !important;
  ${({ borderradius }) =>
    borderradius &&
    css`
      border-radius: ${borderradius}!important;
    `}
  ${({ height, width, backgroundcolor }) => css`
    height: ${height};
    width: ${width};
    background-color: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) =>
    border &&
    css`
      border: ${border}!important;
      border-color: ${borderColor}!important;
      border-style: ${borderStyle && borderStyle}!important;
    `}
`;

export const Step2Bottom = styled.div`
  display: flex;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  justify-content: center;
  align-items: center;
  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      height: ${cstmHeight};
    `}
`;
export const AttemptValue = styled.div`
  width: 15%;
  display: flex;
  justify-content: end;
  font-size: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins500;
`;
export const AttemptScoreContainer = styled.div`
  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      height: ${cstmHeight};
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding-left: ${padding};
      padding-right: ${padding};
    `}
  display: flex;
  flex-direction: column;
  margin: auto;
`;
export const AttemptValContainer = styled.div`
  display: flex;
`;
export const Step1Top = styled.div`
  display: flex;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  justify-content: center;

  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      height: ${cstmHeight};
    `}
  ${({ alignBottom }) =>
    alignBottom &&
    css`
      display: flex;
      align-items: center;
    `}
`;
export const StarImg = styled.img`
  height: ${screenWidth > maximumTabletWidth
    ? "47px"
    : (47 / screenWidth) * 100 + "vw"};
  width: ${screenWidth > maximumTabletWidth
    ? "49px"
    : (49 / screenWidth) * 100 + "vw"};
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
`;

export const AttemptLabel = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins500;
  padding-left: ${screenWidth > maximumTabletWidth ? "45px" : (45 /screenWidth * 100)+"vw"};
`
