import styled, { css } from 'styled-components'
import { Button, MobileStepper } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';


export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => gap && css`
    gap: ${gap};
  `} 
  ${({ postionrelative }) => postionrelative && css`
    position: ${postionrelative};
  `} 
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth ? "25px" : (25 /screenWidth * 100)+"vw"};
  ${({ gap }) => css`
    gap: ${gap};
  `}  
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}  
`

export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}
  ${({ textalign }) => textalign && css`
    text-align: ${textalign};
  `}
  ${({ positionrelative }) => positionrelative && css`
    position: ${positionrelative};
  `}
`

export const PuzzleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 /screenWidth * 100)+"vw"};
`

export const BottomBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 /screenWidth * 100)+"vw"};
`

export const CrossImg = styled.img`
  position: absolute;
  right: 0;
  padding-right: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  height: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  cursor: pointer;
`

export const StarImg = styled.img`
  height: ${screenWidth > maximumTabletWidth ? "34px" : (34 / screenWidth * 100) + "vw"};
  width: ${screenWidth > maximumTabletWidth ? "35px" : (35 / screenWidth * 100) + "vw"};
`

export const RecordCardsContainer = styled.div`
width: ${screenWidth > maximumTabletWidth ? "310px" : (310 / screenWidth * 100) + "vw"};
height: ${screenWidth > maximumTabletWidth ? "90px" : (90 / screenWidth * 100) + "vw"};
gap: ${screenWidth > maximumTabletWidth ? "13.33px" : (13.33 / screenWidth * 100) + "vw"};
display: flex;
`

export const CardContainer = styled.div`
width: ${screenWidth > maximumTabletWidth ? "90px" : (90 / screenWidth * 100) + "vw"};
background-color: ${appColors.modalBackgroundColor};
border: 1px solid ${appColors.cardBorderColor};
box-shadow: 1px 8px 7px 0px #E9E9E9;
border-radius: ${screenWidth > maximumTabletWidth ? "16px" : (16 / screenWidth * 100) + "vw"};
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
padding: ${screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"};
`

export const CardLabel = styled.div`
    font-size: ${screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"};
    font-family: poppins400;
    color: ${appColors.cardLabelColor};
    display: flex;
    text-align: center;
    line-height: ${screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"};
    height: 50%;
`

export const CardValue = styled.div`
    font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"};
    font-family: poppins500;
    color: ${appColors.greyTextColor};
    height: 50%;
`

export const StarContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"};
    position: relative;
`

export const StarRowContainer = styled.div`
    width: 100%;
    display: flex;
    gap: ${screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"};
    align-items: end;
`

export const StarRowLabel = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: ${screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth * 100) + "vw"};
    font-family: poppins400;
    color: ${appColors.greyTextColor};
`

export const StarRowValue = styled.div`
    width: 70%;
    display: flex;
    gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 / screenWidth * 100) + "vw"};
`

export const FilterContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: ${screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"};;
`

export const FilterLabel = styled.div`
    font-size: ${screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"};
    font-family: poppins500;
    color: ${appColors.greyTextColor};
`

