import { useEffect, useLayoutEffect } from "react";
import { KeyButton, KeyboardContainer, Row } from "./styles";
import { maximumTabletWidth, screenWidth } from "../../utils";

const CustomKeyboard = ({
  isBoardDisable,
  backspaceHidden,
  enabledKeys,
  onKeyPress,
}) => {
 
  const allKeys = [
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    ["Z", "X", "C", "V", "B", "N", "M", "⌫"],
  ];

  const handleClick = (key) => {
    if (key == "⌫") {
      onKeyPress("backspace");
    } else {
      onKeyPress(key);
    }
  };

  const isDisabled = (key) => {
    return !enabledKeys.includes(key);
  };

  return (
    <KeyboardContainer 
      height={screenWidth > maximumTabletWidth
        ? "165px"
        : (165 / screenWidth) * 100 + "vw"}
        gap={screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth) * 100 + "vw"}
    >
      {allKeys.map((row, rowIndex) => (
        <Row key={rowIndex} gap={screenWidth > maximumTabletWidth ? "15px" : (6 / screenWidth) * 100 + "vw"}>
          {
            row.map((key, keyIndex) => (
                <KeyButton
                  width={screenWidth > maximumTabletWidth ? "33px" : (30 / screenWidth) * 100 + "vw"}
                  height={screenWidth > maximumTabletWidth ? "43px" : (40 / screenWidth) * 100 + "vw"}
                  fontsize={screenWidth > maximumTabletWidth ? "22px" : (22 / screenWidth) * 100 + "vw"}
                  key={keyIndex}
                  onClick={() => handleClick(key)}
                  disabled={isBoardDisable ? isDisabled(key) : null}
                >
                  {key}
                </KeyButton>
              ))
            }
        </Row>
      ))}
    </KeyboardContainer>
  );
};
export default CustomKeyboard;
