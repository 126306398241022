import moment from "moment";
import { toast } from "react-toastify";

export const screenWidth = window.innerWidth;

export const screenHeight = window.innerHeight;

export const maximumTabletWidth = 800;

// Function to get a random image
export const getRandomImage = (imageArr) => {
  // Generate a random index within the range of the array
  const randomIndex = Math.floor(Math.random() * imageArr.length);
  // Return the image at the random index
  let randomImage = imageArr[randomIndex]
  const randomColors = [
    "#A9CEE3",
    "#E8AAB1",
    "#D2AAE4",
    "#E5CDAA",
    "#B7E1A9",
    "#AABEE4",
    "#E3E1AA"
  ]
  let randomColor = randomColors[randomIndex]
  return {randomImage, randomColor};
};

export const activeObjKeyAndValue = (arr) => {
  const [objName, objEntries] = Object.entries(arr[0]).find(([key, value]) => value.isActive) || [null, null];
    return {objName, objEntries}
}

export const internetCheck = () => navigator.onLine;

export const throwInternetIssue = () =>
  toast.error("Please check your internet connection.", {
    toastId: "internetIssue",
});

export const toastCommon = {
  toast: null,
  get: () => {
    return toastCommon.toast;
  },
  set: (data) => {
    toastCommon.toast = data;
  },
  call: (value, msg, type) => {
    toastCommon.toast(value, msg, type)
  },
};

export const navigateCommon = {
  navigate: null,
  get: () => {
    return navigateCommon.navigate;
  },
  set: (data) => {
    navigateCommon.navigate = data;
  },
};

export const getCookieValue = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('cookieName=')) {
      return cookie.substring('cookieName='.length, cookie.length);
    }
  }
  return ''; // Cookie not found
};

// Utility function to generate the initial state
export const generateInitialState = () => {
  const actions = ['add', 'remove', 'change', 'mixup'];
  const initialState = actions.reduce((acc, action) => {
    acc[action] = { step: 0, isActive: false };
    return acc;
  }, {});
  return [initialState];
};


export const convertToLocalTime = (utcTime) => {
  const now = moment();
  let localTimeString = '00:00:00'
  if (utcTime) {
    const target = moment.utc(utcTime, 'HH:mm:ss').local();
    const duration = moment.duration(target.diff(now));

    if (duration.asSeconds() <= 0) {
      localTimeString = '00:00:00';
    } else {
      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');
      localTimeString = `${hours}:${minutes}:${seconds}`
    }
  }
  return localTimeString
};

export const formatTime = (seconds) => {
  const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
  const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
  const s = (seconds % 60).toString().padStart(2, '0');
  return `${h}:${m}:${s}`;
}