import React from "react";
import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label", value = null) => {
    ReactGA.event({category, action, label,value});
  }
  return eventTracker;
}


export default useAnalyticsEventTracker;