import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { BannerWrapper, BlurBackDrop, ContentWrapper, LoadImg, LoaderImage, LoaderWrapper, MoveBtn, NextBtn } from './styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

const Loader = ({ open }) => {

    const [rotation, setRotation] = useState(0);


    const moveX = 1
  const duration = 0.3
  const repeatDelay = 0.85

    // useEffect(() => {
    // const intervalId = setInterval(() => {
    //   // Rotate the image by 90 degrees clockwise on every second
    //   setRotation(rotation => rotation + 90);   
    // }, 1150);
    //  // Clean up the interval on component unmount
    //  return () => clearInterval(intervalId);
    // }, []); // Empty dependency array to run the effect only once after initial render

  return (
    // <LoaderWrapper>
    //     <ContentWrapper id={"contentContainer"}>
    //     <BannerWrapper>
        <BlurBackDrop open={open}>
            
              
            <LoaderImage
              height={screenWidth > maximumTabletWidth ? "35.5px" : (35.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "38px" : (38 / screenWidth * 100) + "vw"}
              // transform={`rotate(${rotation}deg)`}
              src={appImages.loadIcon} 
            />
            </BlurBackDrop>
    // </BannerWrapper>
    // </ContentWrapper>
    // </LoaderWrapper>
  )
}

export default Loader;