import React, { useCallback, useEffect, useState } from 'react'
import { LoadImg, MoveBtn, NextBtn, Step1Bottom, Step1Top, Step2Bottom, StepContainer, StepContent, TextBtn } from './styles'
import { maximumTabletWidth, screenWidth } from '../../utils'
import { appColors } from '../../themes/colorConst'
import { appImages } from '../../themes/imageConstant'
import { useAnimation } from 'framer-motion'

export const Step2 = (props) => {

  const rope = useAnimation()
  const bottomText = useAnimation()
  const opacityDuration = 1
  const xValue = 1; // Set your desired x value here
  const yValue = -1; // Set your desired x value here

  const [animationStarted, setAnimationStarted] = React.useState(false);
 
  const animate = useCallback( async () => {
    // rope.mount()
    // await rope.start({
    //   // opacity: 0,
    //   x: 0,
    //   transition: {  duration: 0 },
    // })
    rope.mount()
    await Promise.all([
      rope.start({
        opacity: 0,
        transition: { opacity: { duration: opacityDuration } },
      }),
    bottomText.mount(),
    bottomText.start({
      opacity: 0,
      transition: { opacity: { duration: opacityDuration } },
    }),
    // rope.start({
    //   y: 0,
    //   transition: { duration: 0 },
    // })
    ])
    
    // Start actual animations
    // rope.mount()
    await rope.start({
      opacity: 1,
      transition: { duration: opacityDuration, delay: opacityDuration/2 },
    })
    // rope.mount()
    // await rope.start({
    //   y: yValue,
    //   transition: { duration: 0 }, // No duration for x transition
    // });
    // Add some delay before starting the third animation
    await new Promise((resolve) => setTimeout(resolve, 1 * 1000));

    // Start the third animation
    // rope.mount()
    // await rope.start({
    //   y: 0,
    //   transition: { duration: 0 }, // No duration for y transition
    // });

    //Start the fourth animation
    bottomText.mount()
    await bottomText.start({
      opacity: 1,
      transition: { duration: opacityDuration },
    })
    //Start the fifth animation
    // rope.mount()
    // await rope.start({
    //   x: -xValue,
    //   transition: { duration: 0 }, // No duration for y transition
    // });
    //Start the sixth animation
    //  await rope.start({
    //   x: 0,
    //   transition: { duration: 0}, // No duration for y transition
    // });
    // Add some delay after the third animation is completed
    await new Promise((resolve) => setTimeout(resolve, 1 * 500));
    setTimeout(animate, 1 * 1000);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      animate()
    }, 1000)

    return () => {
      rope?.stop();
      bottomText.stop();
    };
  }, [])

  return (
    <StepContainer>
      <StepContent
        gap={screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"}
      >
        <Step1Top
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        >
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              S
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              P
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              A
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              C
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              E
            </TextBtn>
          </NextBtn>

        </Step1Top>
        <MoveBtn
          initial={{ opacity: 0, x: 0 }}
          animate={rope}
          contentCenter={'center'}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <img style={{
              height: screenWidth > maximumTabletWidth ? "140px" : (140 / screenWidth * 100) + "vw",
              paddingRight: screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw",
            }} src={appImages.arrowRope} />
          </div>
        </MoveBtn>
        <MoveBtn
          initial={{ opacity: 0, x: 0 }}
          animate={bottomText}
        >
          <Step2Bottom
            gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            // marginRight={marginRight}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                D
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                E
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                C
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                A
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                F
              </TextBtn>
            </NextBtn>
          </Step2Bottom>
        </MoveBtn>
      </StepContent>
    </StepContainer>
  )
}

export default React.memo(Step2)
