import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { toastCommon } from '../../utils';

const CustomizedSnackbars = (props) => {

    // const {
    //     openToast,
    //     message,
    //     toastType
    // } = props

    const [openToast, setOpenToast] = React.useState(false);
    const [toastMsg, setToastMsg] = React.useState('');
    const [toastType, setToastType] = React.useState('');


    React.useLayoutEffect(() => {
      toastCommon.set(handleOpenToast)
    }, [])

    const handleOpenToast = (value = false, msg = "", type = "success") => {
      setOpenToast(value)
      setToastMsg(msg)
      setToastType(type)
    }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toastCommon.call(false, toastMsg, toastType)
  };

  return (
    <div>
      <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={toastType}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toastMsg || ""}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default React.memo(CustomizedSnackbars);