import styled, { css } from 'styled-components'
import { Button, MobileStepper } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';

// export const OnboardingWrapper = styled.div`
//   flex: 11;
//   padding: 0px 0 70px 0;
//   height: auto;
//   display: flex;
//   flex-direction: column;

//   ${({ noBottomPadding }) => noBottomPadding && css`
//     padding-bottom: 0;
//   `}

//   ${({ errMsg }) => errMsg && css`
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     padding: 20px 0;
//     height: 100%;
//   `}

//   ${({ imageFilters }) => imageFilters && css`
//     min-height: 100%;
//   `}

//   @media (max-width: 500px) {
//     width: 978px;
//   }

//   .ui.fluid.input>input::selection {
//     color: black; !important;
//     background: #ACCEF7 !important;
//   }
// `

export const OnboardingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  ${({ cstmheight }) => cstmheight && css`
  height: ${cstmheight}!important;
  `}
  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
width: 500px;
height: 100%;
overflow: hidden;
position: relative;

@media (max-width: ${maximumTabletWidth}px) {
  width: 100%;
}

`

export const BannerWrapper = styled.div`
  height: 60%;
  position: relative;
`
export const BottomCWrapper = styled.div`
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${({ cstmheight }) => cstmheight && css`
    height: ${cstmheight}!important;
  `}
  
`

export const Heading = styled(motion.div)`
  padding: 3vh 4vw 2vh 4vw;
  ${({ padding }) => padding && css`
    padding: ${padding};
  `}
`

export const HeadingText1 = styled.div`
  font-family: poppins700;
  
  ${({ fontSize, fontFamily, fontColor, marginTop }) => fontSize && css`
    font-size: ${fontSize ? fontSize : 12+'px'};
    color: ${fontColor ? fontColor : appColors.headingTextColor};
    font-family: ${fontFamily && fontFamily};
  `}
  ${({ padding }) => padding && css`
  padding: ${padding};
  `}
  ${({ marginTop }) => marginTop && css`
    margin-top:${marginTop};
  `}
  ${({ opacity }) => opacity && css`
    opacity:${opacity};
  `}
`

export const Description = styled.div`
  font-family: poppins500;
  display: flex;
  justify-content: space-between;
  padding: 0 4vw 4vh 4vw;
`

export const NextBtn = styled(motion(Button))`
  text-transform: none!important;
  min-width: auto!important;
  border-radius: 8px!important;
  padding: 0!important;
  box-shadow: none!important;
  ${({ boxshadow}) => boxshadow && css`
    box-shadow: -3px 3px 15px 1px #5E5E5EB2!important;
  `}
  ${({ boxshadowactionbtn}) => boxshadowactionbtn && css`
    box-shadow: 0px 6px 10px 0px #5E5E5EB2!important;
  `}
  ${({ borderradius }) => borderradius && css`
  border-radius: ${borderradius}!important;
  `}
  ${({ height, width, backgroundcolor }) => css`
    height: ${height};
    width: ${width};
    background-color: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) => border && css`
    border: ${border}!important;
    border-color: ${borderColor}!important;
    border-style: ${borderStyle && borderStyle}!important;
    // border-width: revert!important;
  `}

  ${({ cursor }) => cursor && css`
    cursor: ${cursor}!important;
  `}
`

export const MoveBtn = styled(motion.div)`
  display: flex;
  align-items: center;
  ${({ contentCenter }) => contentCenter && css`
    justify-content: center;
  `}
  ${({ height }) => height && css`
    height: ${height};
  `}
  ${({ gap }) => gap && css`
    gap: ${gap};
  `}
  ${({ width }) => width && css`
    width: 100%;
  `}
  ${({ alignitem }) => alignitem && css`
  align-items: ${alignitem}!important;
  `}
  ${({ positionabsolute }) => positionabsolute && css`
  position: absolute!important;
  `}
`

export const Stepper = styled(MobileStepper)`

.MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  ${({ activestepstyle }) => css`
    background-color: ${activestepstyle.color}!important;
    width: ${activestepstyle.width}!important;
    height: ${activestepstyle.height}!important;
  `}
  border-radius: 50px!important;
}

.MuiMobileStepper-dot {
  ${({ stepstyle }) => stepstyle && css`
    background-color: ${stepstyle.color}!important;
    width: ${stepstyle.width}!important;
    height: ${stepstyle.height}!important;
  `}
}

`

export const StepContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  ${({ cstmHeight }) => cstmHeight && css`
    height: ${cstmHeight};
  `}
`

export const TextBtn = styled(motion.span)`
  font-family: poppins700;
  ${({ fontFamily }) => fontFamily && css`
  font-family: ${fontFamily}!important;
  `}
  ${({ color, fontSize }) => css`
    color: ${color};
    font-size: ${fontSize};
  `}
  ${({ positionabsolute }) => positionabsolute && css`
    position: absolute;
  `}
`

export const LeftContent = styled.div`
`

export const RightContent = styled.div`
`

export const StepContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
    justify-content: space-around;
  `}
  ${({ justifyend}) => justifyend && css`
    justify-content: end;
  `}
  ${({ justifycenter }) => justifycenter && css`
    justify-content: center;
  `}

  ${({ alignCenter }) => alignCenter && css`
    align-items: center;
  `}
`

export const Step1Top = styled.div`
  display: flex;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
  `}
  ${({ alignBottom }) => alignBottom && css`
    display: flex;
    align-items: end;
  `}
  ${({ positionabsolute }) => positionabsolute && css`
    position: absolute;
  `}
`

export const Step1Bottom = styled.div`
display: flex;
${({ gap }) => css`
  gap: ${gap};
`}
justify-content: end;
padding-right: 25px;
`

export const Step2Bottom = styled.div`
display: flex;
${({ gap }) => css`
  gap: ${gap};
`}
${({ cstmHeight}) => cstmHeight && css`
    height: ${cstmHeight};
  `}
`

export const LoadImg = styled.img`
  ${({ height, width, transform }) => css`
  height: ${height};
  width: ${width};
  transform: ${transform};
  // transition: transform 0.3s ease;
  `}
`

export const AttemptScoreContainer = styled.div`
  ${({ cstmHeight }) => cstmHeight && css`
    height: ${cstmHeight};
  `}
  ${({ padding }) => padding && css`
    padding: ${padding};
  `}
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`

export const AttemptValContainer = styled.div`
  display: flex;
`

export const AttemptLabel = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins700;
  padding-left: ${screenWidth > maximumTabletWidth ? "45px" : (45 /screenWidth * 100)+"vw"};
`

export const AttemptValue = styled.div`
  width: 15%; 
  display: flex; 
  justify-content: end;
  font-size: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins500;
`

export const GameBtnContainer = styled.div`
  justify-content: center;
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex; 
  align-items: center; 
  left: 50%; 
  transform: translate(-50%, -50%);
`