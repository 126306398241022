export const appConstants = {
    gameMessages: {
        "add": {
            1: "Select where to add a letter",
            2: "Add a new letter"
        },
        "initial": {
            1: "Select an action"
        },
        "remove": {
            1: "Select a letter to remove"
        },
        "change": {
            1: "Select a letter to change",
            2: "Enter a new letter"
        },
        "mixup": {
            1: "Mix up the letters"
        }
    }
}