import React, { useCallback, useEffect, useState } from "react";
import {
  AddSte1Container,
  AddStep1Container,
  DashedAddImg,
  DashedLine,
  DashedLinesContainer,
  GameBtnContainer,
  GenerateWord,
  MoveBtn,
  NextBtn,
  RemoveChilcIcon,
  RemoveIcon,
  Step1Top,
  StepContainer,
  StepContent,
  TextBtn,
} from "./styles";
import {
  activeObjKeyAndValue,
  maximumTabletWidth,
  screenWidth,
  toastCommon,
} from "../../utils";
import { appColors } from "../../themes/colorConst";
import { appImages } from "../../themes/imageConstant";
import { appConstants } from "../../themes/appConstant";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";

const CenterContent = (props) => {
  const {
    word,
    wordCopyForUndo,
    action,
    handleActionClick,
    handleAddClick,
    handleRemoveEmptyAddInput,
    temporaryWordIndex,
    handleWordLocked,
    handleUndoWord,
    attempts,
    isWordLocked,
    handleRemoveClick,
    handleChangeClick,
    handleInitializeChangeAction,
    handleInitializeMixupAction,
    activeWordIndex,
    validateResponse,
    actionBtn1,
    actionBtn2,
    actionBtn3,
    actionBtn4,
    actionBtnColor1,
    setActionBtnColor1,
    actionBtnColor2,
    setActionBtnColor2,
    actionBtnColor3,
    setActionBtnColor3,
    actionBtnColor4,
    setActionBtnColor4,
    gameData,
    actionTitleMessageContent,
    setTemporaryWord
  } = props;

  
  const gaEventTrackerForActionButtons = useAnalyticsEventTracker('Action Buttons');

  const initialStep = useCallback(() => {
    return (
      <Step1Top
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : 60;
            return (
              <NextBtn
                key={index}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                backgroundcolor={
                  isWordLocked
                    ? validateResponse?.message == "Change Successful!"
                    ? appColors.successBtnColor
                    : appColors.errorBtnColor
                    : appColors.whiteColor
                }
                boxshadow
                cursor={'default'}
              >
                <TextBtn
                  color={
                    isWordLocked ? appColors.whiteText : appColors.greyTextColor
                  }
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "20px"
                      : (20 / screenWidth) * 100 + "vw"
                  }
                >
                  {item}
                </TextBtn>
              </NextBtn>
            );
          })}
      </Step1Top>
    );
  }, [isWordLocked, word, validateResponse]);

  const addStep1 = useCallback(() => {
    return (
      <Step1Top
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      // gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 30 : word?.length >= 5 ? 40 : 50;
            return (
              <AddStep1Container key={index}
                initial={{ opacity:0}}
                animate={{ opacity:1 }}
              
                transition={{ duration: 1,}}
              >
                <div>
                  <DashedAddImg
                    src={appImages.plus}
                    onClick={() => {
                      handleAddClick("add", index);
                    }}
                  />
                  <DashedLinesContainer>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={appColors.themeColor3}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={appColors.themeColor3}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={appColors.themeColor3}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={appColors.themeColor3}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "2px"
                          : (2 / screenWidth) * 100 + "vw"
                      }
                      background={appColors.themeColor3}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={"transparent"}
                    ></DashedLine>
                    <DashedLine
                      height={
                        screenWidth > maximumTabletWidth
                          ? "4px"
                          : (4 / screenWidth) * 100 + "vw"
                      }
                      background={"transparent"}
                    ></DashedLine>
                  </DashedLinesContainer>
                </div>
                <NextBtn
                  variant="contained"
                  size="small"
                  height={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  width={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  backgroundcolor={appColors.whiteColor}
                  boxshadow
                >
                  <TextBtn
                    color={appColors.greyTextColor}
                    fontSize={
                      screenWidth > maximumTabletWidth
                        ? "20px"
                        : (20 / screenWidth) * 100 + "vw"
                    }
                  >
                    {item}
                  </TextBtn>
                </NextBtn>
              </AddStep1Container>
            );
          })}

        <div>
          <DashedAddImg
            src={appImages.plus}
            onClick={() => handleAddClick("add", word?.length)}
            initial={{ opacity:0}}
      animate={{ opacity:1 }}
     
      transition={{ duration: 1,}}
          />
          <DashedLinesContainer
            initial={{ opacity:0}}
            animate={{ opacity:1 }}
           
            transition={{ duration: 1,}}
          >
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={appColors.themeColor3}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={appColors.themeColor3}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={appColors.themeColor3}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={appColors.themeColor3}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "2px"
                  : (2 / screenWidth) * 100 + "vw"
              }
              background={appColors.themeColor3}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={"transparent"}
            ></DashedLine>
            <DashedLine
              height={
                screenWidth > maximumTabletWidth
                  ? "4px"
                  : (4 / screenWidth) * 100 + "vw"
              }
              background={"transparent"}
            ></DashedLine>
          </DashedLinesContainer>
        </div>
      </Step1Top>
    );
  }, [word, action]);

  const addStep2 = useCallback(() => {
    return (
      <Step1Top
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : word?.length >= 5 ? 50 : 50;
            return (
              <NextBtn
                initial={{ opacity:0}}
                animate={{ opacity:1 }}
              
                transition={{ duration: 1,}}
                key={index}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteColor}
                border={item == "" && "1px solid"}
                borderColor={item == "" && appColors.borderColor}
                borderStyle={item == "" && "dashed"}
                boxshadow
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "20px"
                      : (20 / screenWidth) * 100 + "vw"
                  }
                >
                  {item}
                </TextBtn>
              </NextBtn>
            );
          })}
      </Step1Top>
    );
  }, [word, action]);

  const removeStep1 = useCallback(() => {
    return (
      <Step1Top
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : word?.length >= 5 ? 60 : 60;
            return (
              <Step1Top positionrelative={"relative"}>
                <RemoveIcon
                  onClick={() => handleRemoveClick("remove", index, item)}
                >
                  <RemoveChilcIcon></RemoveChilcIcon>
                </RemoveIcon>
                <NextBtn
                  key={index}
                  variant="contained"
                  size="small"
                  height={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  width={
                    screenWidth > maximumTabletWidth
                      ? `${wordDimension}px`
                      : (wordDimension / screenWidth) * 100 + "vw"
                  }
                  onClick={() => handleRemoveClick("remove", index, item)}
                  backgroundcolor={appColors.whiteColor}
                  border={"1px solid"}
                  borderColor={appColors.borderColor}
                  borderStyle={"dashed"}
                  boxshadow
                >
                  <TextBtn
                    color={appColors.greyTextColor}
                    fontSize={
                      screenWidth > maximumTabletWidth
                        ? "20px"
                        : (20 / screenWidth) * 100 + "vw"
                    }
                  >
                    {item}
                  </TextBtn>
                </NextBtn>
              </Step1Top>
            );
          })}
      </Step1Top>
    );
  }, [word, action]);

  const changeStep1 = useCallback(() => {
    return (
      <Step1Top
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : word?.length >= 5 ? 60 : 60;
            return (
              <NextBtn
                key={index}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteColor}
                border={"1px solid"}
                borderColor={appColors.borderColor}
                borderStyle={"dashed"}
                onClick={() => {
                  handleChangeClick("change", index, item)
                }}
                boxshadow
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "20px"
                      : (20 / screenWidth) * 100 + "vw"
                  }
                >
                  {item}
                </TextBtn>
              </NextBtn>
            );
          })}
      </Step1Top>
    );
  }, [word, action]);

  const changeStep2 = useCallback(() => {
    return (
      <Step1Top
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : word?.length >= 5 ? 60 : 60;
            return (
              <NextBtn
                key={index}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteColor}
                border={item == "" && "1px solid"}
                borderColor={item == "" && appColors.borderColor}
                borderStyle={item == "" && "dashed"}
                onClick={() => {
                  // handleUndoWord()
                  if (temporaryWordIndex < 0 && item) {
                    handleChangeClick("change", index, item)
                  }
                }}
                boxshadow
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "20px"
                      : (20 / screenWidth) * 100 + "vw"
                  }
                >
                  {item}
                </TextBtn>
              </NextBtn>
            );
          })}
      </Step1Top>
    );
  }, [word, action]);

  const mixupStep1 = useCallback(() => {
    return (
      <Step1Top
        gap={
          screenWidth > maximumTabletWidth
            ? "10px"
            : (10 / screenWidth) * 100 + "vw"
        }
        height={(screenWidth > maximumTabletWidth ? "75px" : (75 / screenWidth) * 100 + "vw")}
      >
        {word &&
          word.map((item, index) => {
            let wordDimension =
              word?.length >= 7 ? 40 : word?.length >= 6 ? 50 : word?.length >= 5 ? 60 : 60;
            return (
              <NextBtn
                key={index}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? `${wordDimension}px`
                    : (wordDimension / screenWidth) * 100 + "vw"
                }
                backgroundcolor={appColors.whiteColor}
                border={activeWordIndex === index && "1px solid"}
                borderColor={activeWordIndex === index && appColors.borderColor}
                borderStyle={activeWordIndex === index && "dashed"}
                // onClick={() => handleChangeClick("change", index, item)}
                boxshadow
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "20px"
                      : (20 / screenWidth) * 100 + "vw"
                  }
                >
                  {item}
                </TextBtn>
              </NextBtn>
            );
          })}
      </Step1Top>
    );
  }, [word, action]);

  const renderInputs = () => {
    switch (true) {
      case action[0]["add"]["step"] == 1 && action[0]["add"]["isActive"]:
        return addStep1();

      case action[0]["add"]["step"] == 2 && action[0]["add"]["isActive"]:
        return addStep2();

      case action[0]["remove"]["step"] == 1 && action[0]["remove"]["isActive"]:
        return removeStep1();

      case action[0]["change"]["step"] == 1 && action[0]["change"]["isActive"]:
        return changeStep1();

      case action[0]["change"]["step"] == 2 && action[0]["change"]["isActive"]:
        return changeStep2();

      case action[0]["mixup"]["step"] == 1 && action[0]["mixup"]["isActive"]:
        return mixupStep1();

      default:
        return initialStep();
    }
  };


  return (
    <StepContainer
      initial={{ opacity: gameData == null ? 0 : 1}}
      animate={{ opacity:1}}
      transition={{delay:3.5}}
      cstmpaddingtop={
        attempts?.length == 0
          ? screenWidth > maximumTabletWidth
            ? "40px"
            : (30 / screenWidth) * 100 + "vh"
          : "false"
      }
      // cstmHeight={"100%"}
      gap={
        screenWidth > maximumTabletWidth
          ? "15px"
          : (8 / screenWidth) * 100 + "vh"
      }
      contentstarttop={"flex-start"}
    >
      <StepContent
        gap={
          screenWidth > maximumTabletWidth
            ? "35px"
            : (15 / screenWidth) * 100 + "vh"
        }
        zindex
      >
        {renderInputs()}
        {/* game play buttons */}
        {!isWordLocked && (
          <GameBtnContainer>
            <Step1Top
              gap={
                screenWidth > maximumTabletWidth
                  ? "10px"
                  : (10 / screenWidth) * 100 + "vw"
              }
            >
              {/* <MoveBtn
                  initial={{ background: 'red' }}
                  animate={actionBtn1}
                > */}
              <NextBtn
                initial={{ background: appColors.whiteColor }}
                animate={actionBtn1}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "37.5px"
                    : (37.5 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "80px"
                    : (80 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={actionBtnColor1}
                borderradius={
                  screenWidth > maximumTabletWidth
                    ? "20px"
                    : (20 / screenWidth) * 100 + "vw"
                }
                onClick={() => {
                  if (word?.length < 7) {
                    if (action[0]["add"]["step"] == 2) {
                      handleRemoveEmptyAddInput();
                    }
                    handleUndoWord()
                    handleActionClick("add");
                  }
                }}
                boxshadowactionbtn={true}
              >
                
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "12px"
                      : (12 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins600"}
                >
                  Add
                </TextBtn>
              </NextBtn>
              {/* </MoveBtn> */}
              <NextBtn
                disabled={word?.length <= 1}
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "37.5px"
                    : (37.5 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "80px"
                    : (80 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={actionBtnColor2}
                borderradius={
                  screenWidth > maximumTabletWidth
                    ? "20px"
                    : (20 / screenWidth) * 100 + "vw"
                }
                onClick={() => {
                  if (word?.length > 1) {
                    handleUndoWord()
                    handleActionClick("remove");
                  }
                }}
                boxshadowactionbtn={true}
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "12px"
                      : (12 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins600"}
                >
                  Remove
                </TextBtn>
              </NextBtn>
              <NextBtn
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "37.5px"
                    : (37.5 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "80px"
                    : (80 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={actionBtnColor3}
                borderradius={
                  screenWidth > maximumTabletWidth
                    ? "20px"
                    : (20 / screenWidth) * 100 + "vw"
                }
                onClick={() => {
                  if (action[0]["change"]["step"] == 2) {
                    handleInitializeChangeAction();
                  }
                  handleUndoWord()
                  handleActionClick("change");
                }}
                boxshadowactionbtn={true}
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "12px"
                      : (12 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins600"}
                >
                  Change
                </TextBtn>
              </NextBtn>
              <NextBtn
                variant="contained"
                size="small"
                height={
                  screenWidth > maximumTabletWidth
                    ? "37.5px"
                    : (37.5 / screenWidth) * 100 + "vw"
                }
                width={
                  screenWidth > maximumTabletWidth
                    ? "80px"
                    : (80 / screenWidth) * 100 + "vw"
                }
                borderradius={
                  screenWidth > maximumTabletWidth
                    ? "20px"
                    : (20 / screenWidth) * 100 + "vw"
                }
                backgroundcolor={actionBtnColor4}
                onClick={() => {
                  if (action[0]["mixup"]["step"] == 1) {
                    handleInitializeMixupAction();
                  }
                  handleUndoWord()
                  handleActionClick("mixup");
                }}
                boxshadowactionbtn={true}
              >
                <TextBtn
                  color={appColors.greyTextColor}
                  fontSize={
                    screenWidth > maximumTabletWidth
                      ? "12px"
                      : (12 / screenWidth) * 100 + "vw"
                  }
                  fontFamily={"poppins600"}
                >
                  Mix Up
                </TextBtn>
              </NextBtn>
            </Step1Top>
          </GameBtnContainer>
        )}
        {temporaryWordIndex < 0 && !isWordLocked && (
          <GenerateWord
            initial={{ opacity: gameData == null ? 0 : 1 }}
            animate={actionTitleMessageContent}
            // transition={{delay:4.5,duration:0.2}}
            cstmtoppadding={"0"}
            fontFamily={"poppins500"}
          >
            {activeObjKeyAndValue(action).objName &&
            activeObjKeyAndValue(action).objEntries.step
              ? appConstants.gameMessages[activeObjKeyAndValue(action).objName][
                  activeObjKeyAndValue(action).objEntries.step
                ]
              : appConstants.gameMessages["initial"][1]}
          </GenerateWord>
        )}
      </StepContent>
      {/** Save & Undo button section */}

      {temporaryWordIndex >= 0 && !isWordLocked && (
        <>
          <TextBtn
            color={appColors.greyTextColor}
            fontSize={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            fontFamily={"poppins400"}
          >
            Ready to lock it in?
          </TextBtn>
          <StepContent
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vh"
            }
            initial={{ opacity: 0 }}
            animate={{
              opacity: [0.5,1],
              transition: {
                duration: 0.2,
                ease: 'easeInOut'
              }
              
            }}
          >
            <NextBtn
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? "50px"
                  : (50 / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? "286px"
                  : (286 / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.successDarkGreen}
              borderradius={
                screenWidth > maximumTabletWidth
                  ? "50px"
                  : (50 / screenWidth) * 100 + "vw"
              }
              onClick={() => {
                if (attempts.length > 0 && ( word.join("").toLowerCase() == attempts[attempts.length - 1].join("").toLowerCase())) {
                  setTemporaryWord("")
                  toastCommon.call(true, "Please submit a different word.", 'error')
                  handleUndoWord(activeObjKeyAndValue(action).objName || null)
                } else {
                  if (window.navigator.onLine) {
                    handleWordLocked(activeObjKeyAndValue(action).objName || null);
                    if (activeObjKeyAndValue(action).objName == 'add') {
                      gaEventTrackerForActionButtons('Action_Clicks', "Add", 'count')
                      
                    } else if (activeObjKeyAndValue(action).objName == 'remove') {
                      gaEventTrackerForActionButtons('Action_Clicks', "Remove", 'count')
                    } else if (activeObjKeyAndValue(action).objName == 'change') {
                      gaEventTrackerForActionButtons('Action_Clicks', "Change", 'count')
                    } else {
                      gaEventTrackerForActionButtons('Action_Clicks', "Mixup", 'count')
                    }
                    
                  } else {
                    toastCommon.call(true, "Please check your internet connection.", 'error')
                  }
                }
              }}
              boxshadowactionbtn
            >
              <TextBtn
                color={appColors.whiteColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "14px"
                    : (14 / screenWidth) * 100 + "vw"
                }
                fontFamily={"poppins600"}
                paddingtopcstm={screenWidth > maximumTabletWidth
                  ? "0.5px"
                  : (0.5 / screenWidth) * 100 + "vw"}
                  
              >
                LOCK IN CHANGE
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? "50px"
                  : (50 / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? "286px"
                  : (286 / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.whiteColor}
              borderradius={
                screenWidth > maximumTabletWidth
                  ? "50px"
                  : (50 / screenWidth) * 100 + "vw"
              }
              boxshadowactionbtn
              onClick={() => {
                handleUndoWord(activeObjKeyAndValue(action).objName || null)
              }}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "14px"
                    : (14 / screenWidth) * 100 + "vw"
                }
                fontFamily={"poppins600"}
                paddingtopcstm={screenWidth > maximumTabletWidth
                  ? "0.5px"
                  : (0.5 / screenWidth) * 100 + "vw"}
              >
                UNDO CHANGE
              </TextBtn>
            </NextBtn>
          </StepContent>
        </>
      )}
    </StepContainer>
  );
};

export default React.memo(CenterContent);

