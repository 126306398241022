import styled, { css } from 'styled-components'
import Popover from '@mui/material/Popover';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';

export const PopoverComponent = styled(Popover)`
margin-top: ${screenWidth > maximumTabletWidth ? "10px" : (10 /screenWidth * 100)+"vw"}!important;
margin-left: ${screenWidth > maximumTabletWidth ? "25px" : (25 /screenWidth * 100)+"vw"}!important;
.MuiPopover-paper {
    background-color: ${appColors.filterPopColor};
    border-radius: unset;
    border: 1px solid ${appColors.blackColor};
    box-shadow: none!important;
}
`