import axios from "axios";
// import { decryptData, encryptData } from "../common/crypto";
import jsonData from '../config/config.json'
import { getCookieValue } from "../utils";

const APIKit = axios.create({ baseURL: jsonData[process.env.NODE_ENV].baseUrl, timeout: 60000000, });
// APIKit.defaults.withCredentials = true;

APIKit.interceptors.request.use(async (config) => {
  
  // if (process.env.NODE_ENV == 'production') {
  //   if (config.data) {
  //     config.data = {
  //       payload: encryptData(config.data),
  //     };
  //   }
  // }
  
  if ((getCookieValue())) config.headers["Authorization"] = `${getCookieValue()}`;

  return config;
});

// if (process.env.NODE_ENV == 'production') {

//   APIKit.interceptors.response.use(
//     async (response) => {
//       response.data = decryptData(response.data);
//       return response;
//     },
//     async (error) => {
//       let originalRequest = error.config;
//       error.response.data = decryptData(error.response.data);
//       return Promise.reject(error);
//     },
//   );
// }

export default APIKit;
