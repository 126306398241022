import { types } from "../actionTypes";

export const isUserExistInitiate = (data) => ({
    type: types.IS_USER_EXIST_LOAD,
    payload: data
})

export const isUserExistSuccess = (data) => ({
    type: types.IS_USER_EXIST_SUCCESS,
    payload: data
})

export const isUserExistFail = (error) => ({
    type: types.IS_USER_EXIST_FAIL,
    payload: error
})

export const getWordInitiate = (data) => ({
    type: types.GET_WORD_LOAD,
    payload: data
})

export const getWordSuccess = (data) => ({
    type: types.GET_WORD_SUCCESS,
    payload: data
})

export const getWordFail = (error) => ({
    type: types.GET_WORD_FAIL,
    payload: error
})

export const getProfileInitiate = (data) => ({
    type: types.GET_PROFILE_LOAD,
    payload: data
})

export const getProfileSuccess = (data) => ({
    type: types.GET_PROFILE_SUCCESS,
    payload: data
})

export const getProfileFail = (error) => ({
    type: types.GET_PROFILE_FAIL,
    payload: error
})

export const completeOnboardingInitiate = (data) => ({
    type: types.COMPLETE_ONBOARDING_LOAD,
    payload: data
})

export const completeOnboardingSuccess = (data) => ({
    type: types.COMPLETE_ONBOARDING_SUCCESS,
    payload: data
})

export const completeOnboardingFail = (error) => ({
    type: types.COMPLETE_ONBOARDING_FAIL,
    payload: error
})

export const validateWordInitiate = (data) => ({
    type: types.VALIDATE_WORD_LOAD,
    payload: data
})

export const validateWordSuccess = (data) => ({
    type: types.VALIDATE_WORD_SUCCESS,
    payload: data
})

export const validateWordFail = (error) => ({
    type: types.VALIDATE_WORD_FAIL,
    payload: error
})

export const yourPerformanceInitiate = (data) => ({
    type: types.YOUR_PERFORMANCE_LOAD,
    payload: data
})

export const yourPerformanceSuccess = (data) => ({
    type: types.YOUR_PERFORMANCE_SUCCESS,
    payload: data
})

export const yourPerformanceFail = (error) => ({
    type: types.YOUR_PERFORMANCE_FAIL,
    payload: error
})

export const yourResultInitiate = (data) => ({
    type: types.YOUR_RESULT_LOAD,
    payload: data
})

export const yourResultSuccess = (data) => ({
    type: types.YOUR_RESULT_SUCCESS,
    payload: data
})

export const yourResultFail = (error) => ({
    type: types.YOUR_RESULT_FAIL,
    payload: error
})

export const botStepsInitiate = (data) => ({
    type: types.BOT_STEPS_LOAD,
    payload: data
})

export const botStepsSuccess = (data) => ({
    type: types.BOT_STEPS_SUCCESS,
    payload: data
})

export const botStepsFail = (error) => ({
    type: types.BOT_STEPS_FAIL,
    payload: error
})

export const getTimeInitiate = (data) => ({
    type: types.GET_TIME_LOAD,
    payload: data
})

export const getTimeSuccess = (data) => ({
    type: types.GET_TIME_SUCCESS,
    payload: data
})

export const getTimeFail = (error) => ({
    type: types.GET_TIME_FAIL,
    payload: error
})

export const userEventsInitiate = (data) => ({
    type: types.USER_EVENTS_LOAD,
    payload: data
})

export const userEventsSuccess = (data) => ({
    type: types.USER_EVENTS_SUCCESS,
    payload: data
})

export const userEventsFail = (error) => ({
    type: types.USER_EVENTS_FAIL,
    payload: error
})

export const updateGamePlayStates = (data, key) => ({
    type: types.UPDATE_GAME_PLAY_STATES,
    payload: data,
    key
})