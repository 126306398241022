import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  GameBtnContainer,
  LoadImg,
  MoveBtn,
  NextBtn,
  Step1Bottom,
  Step1Top,
  Step2Bottom,
  StepContainer,
  StepContent,
  TextBtn,
} from "./styles";
import { maximumTabletWidth, screenWidth } from "../../utils";
import { appColors } from "../../themes/colorConst";
import { appImages } from "../../themes/imageConstant";
import { animateValue, useAnimation, useAnimationControls } from "framer-motion";

export const Step3 = (props) => {
  const finalTextContainer = useAnimation();
  const bottomText = useAnimation();
  const bottomTextRef = useRef(null);
  const textBtn = useAnimation();
  const textBtnRef = useRef(null);
  const textOpacityDuration = 0.5;
  const xValue = 1; // Set your desired x value here
  const yValue = -1; // Set your desired x value here
  const [wordArr, setWordArr] = useState(["S", "P", "A", "C", "E"]);
  const [wordArr2, setWordArr2] = useState(["S", "P", "A", "C", "E", "D"]);
  const [animationStarted, setAnimationStarted] = React.useState(false);

  const animateWords = useCallback( async () => {
    bottomText.mount()
    await bottomText.start({
      // opacity: [0.1,0.5,0.8,1],
      transition: { duration: 0.8, ease: "easeInOut" },
      x: [1, 0, 0],
      y: [-50, 0],
      scale: 1,
      rotate: 0,
    });
    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 1 * 1000));

      // setAnimationStarted(true)
      finalTextContainer.mount()
      bottomText.mount()
      await Promise.all([
        bottomText.start({
          opacity: [1,0.5, 0.1,0],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        finalTextContainer.start({
          opacity: [0,0.1,0.5, 1],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        
      ]);

      await new Promise((resolve) => setTimeout(resolve, 1 * 1000));
     
      textBtn.mount()
      await textBtn.start({
        opacity: 1,
        transition: { duration: textOpacityDuration },
      });


      await new Promise((resolve) => setTimeout(resolve, 1 * 1000));

      finalTextContainer.mount()
      bottomText.mount()
      textBtn.mount()
      await Promise.all([
        textBtn.start({
          opacity: 0,
          transition: { duration: textOpacityDuration },
        }),
        bottomText.start({
          opacity: [0,0.1,0.5, 1],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        finalTextContainer.start({
          opacity: [1,0.5, 0.1,0],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        
      ]);
    }
  }, [textBtn, textOpacityDuration, bottomText]) 
    

  useEffect(() => {

    setTimeout(() => {
      animateWords();
    }, 1000)
    

    return () => {
      textBtn.stop();
      bottomText.stop();
    };
  }, []);

  return (
    <>
      <StepContainer>
        <StepContent
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
          justifycenter
          alignCenter
        >
          <Step1Top
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
            justifyend
          >
            {
              wordArr.map((item) => (
                <MoveBtn
                  initial={{ opacity: 1, x: 1, y: -50 }}
                  animate={bottomText}
                  ref={bottomTextRef}
                  contentCenter={"center"}
                  gap={
                    screenWidth > maximumTabletWidth
                      ? "10px"
                      : (10 / screenWidth) * 100 + "vw"
                  }
                >
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteColor}
                    boxshadow
                    cursor={'default'}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "36px"
                          : (36 / screenWidth) * 100 + "vw"
                      }
                    >
                      {item}
                    </TextBtn>
                  </NextBtn>
                </MoveBtn>
              ))}
          </Step1Top>
          <Step1Top
            positionabsolute
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
          >
          {
              wordArr2.map((item, index) => {
                return (
                  <MoveBtn
                  initial={{ opacity: 0 }}
                  animate={finalTextContainer}
                  contentCenter={"center"}
                  
                  >
                    {
                      index === wordArr2.length - 1 ?
                      (
                        <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteColor}
                    border={"1px solid"}
                    borderColor={appColors.borderColor}
                    borderStyle={"dashed"}
                    boxshadow
                    cursor={'default'}
                  >
                    <MoveBtn
                      initial={{ opacity: 0, x: 0 }}
                      animate={textBtn}
                      ref={textBtnRef}
                      contentCenter={"center"}
                    >
                      <TextBtn
                        color={appColors.greyTextColor}
                        fontSize={
                          screenWidth > maximumTabletWidth
                            ? "36px"
                            : (36 / screenWidth) * 100 + "vw"
                        }
                      >
                        {"D"}
                      </TextBtn>
                    </MoveBtn>
                  </NextBtn>
                      ) : (
                        <NextBtn
                    key={index}
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "50px"
                        : (50 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteColor}
                    borderColor={appColors.borderColor}
                    boxshadow
                    cursor={'default'}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "36px"
                          : (36 / screenWidth) * 100 + "vw"
                      }
                    >
                      {item}
                    </TextBtn>
                  </NextBtn>
                      )
                    }
                  </MoveBtn>
                )
              }
              )}
          </Step1Top>
        </StepContent>
      </StepContainer>
      <GameBtnContainer>
        <Step1Top
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
        >
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.greyBtnColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Add
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Remove
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Change
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Mix Up
            </TextBtn>
          </NextBtn>
        </Step1Top>
      </GameBtnContainer>
    </>
  );
};

export default React.memo(Step3);
