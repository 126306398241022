import React, { useCallback, useEffect, useState } from 'react'
import { LoadImg, MoveBtn, NextBtn, Step1Bottom, Step1Top, StepContainer, StepContent, TextBtn } from './styles'
import { maximumTabletWidth, screenWidth } from '../../utils'
import { appColors } from '../../themes/colorConst'
import { appImages } from '../../themes/imageConstant'
import { useAnimation } from 'framer-motion'

export const Step1 = (props) => {

  const rotatingImg = useAnimation()

  const animateImage = useCallback( async() => {
    rotatingImg.mount()
    rotatingImg.start({
      rotate: 360,
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 2,  // Adjust duration to control speed
        ease: 'linear',  // Use 'linear' for smooth continuous rotation
      },
    });
    
  //   while (true) {
  //     await new Promise((resolve) => setTimeout(resolve, 300));
  //     rotatingImg.mount()

  //   await rotatingImg.start({
  //     rotate: [0, 90],
  //     transition: { duration: 0.3, ease: 'easeInOut' }, // No duration for y transition
  //   });

  //   await new Promise((resolve) => setTimeout(resolve, 300));
  //   rotatingImg.mount()

  //   await rotatingImg.start({
  //     rotate: [90, 180],
  //     transition: { duration: 0.3, ease: 'easeInOut' }, // No duration for y transition
  //   });

  //   await new Promise((resolve) => setTimeout(resolve, 300));
  //   rotatingImg.mount()

  //   await rotatingImg.start({
  //     rotate: [180, 270],
  //     transition: { duration: 0.3, ease: 'easeInOut' }, // No duration for y transition
  //   });

  //   await new Promise((resolve) => setTimeout(resolve, 300));
  //   rotatingImg.mount()

  //   await rotatingImg.start({
  //     rotate: [270, 360],
  //     transition: { duration: 0.3, ease: 'easeInOut' }, // No duration for y transition
  //   });
  // }
  }, [rotatingImg])

  useEffect(() => {
      animateImage()

    return () => {
      rotatingImg?.stop();
    };
  }, [])

  return (
    <StepContainer>
      <StepContent
        gap={screenWidth > maximumTabletWidth ? "15px" : (15 / screenWidth * 100) + "vw"}
      >
        <MoveBtn
          // animate={{
          //   x: [0, moveX, 0],
          //   y: [0, -1, 0],
          //   scale: 1,
          //   rotate: 0,
            
          // }}
          // transition= {{
          //   duration: duration, // Set animation duration for all keyframes
          //   delay: 0,
          //   repeat: Infinity,
          //   repeatDelay: repeatDelay, // Delay between each animation iteration
          // }}
        >
        <Step1Top
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        >
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              C
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              H
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              A
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              N
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              G
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              E
            </TextBtn>
          </NextBtn>
        </Step1Top>
          </MoveBtn>
        <Step1Bottom
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        >
          <NextBtn
            boxshadow
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.greyTextColor}
            cursor={'default'}
          >
              <MoveBtn
                height={"100%"}
                initial={{ rotate: 0, }}
                animate={rotatingImg}
          >
            <LoadImg
              height={screenWidth > maximumTabletWidth ? "35.5px" : (35.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "38px" : (38 / screenWidth * 100) + "vw"}
              transform={`rotate(${0}deg)`}
              src={appImages.loadIcon} 
            />
            </MoveBtn>
          </NextBtn>
          <MoveBtn
            // animate={{
            //   x: [0, moveX, 0],
            //   y: 0,
            //   scale: 1,
            //   rotate: 0,
              
            // }}
            // transition= {{
            //   duration: duration, // Set animation duration for all keyframes
            //   delay: 0,
            //   repeat: Infinity,
            //   repeatDelay: repeatDelay, // Delay between each animation iteration
            // }}
          >
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            // marginRight={marginRight}
            transform={`rotate(${0}deg)`}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              I
            </TextBtn>
          </NextBtn>
          </MoveBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              T
            </TextBtn>
          </NextBtn>
        </Step1Bottom>
      </StepContent>
    </StepContainer>
  )
}

export default React.memo(Step1)
