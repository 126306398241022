import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  BannerWrapper,
  ContentWrapper,
  GameContainer,
  LeftOptionsFirst,
  NextBtn,
  OnboardingWrapper,
  OptionsFirstImg,
  StepContent,
  TextBtn,
  TopOptions,
  TopOptionsLeft,
} from "../gamePlay/styles";
import { maximumTabletWidth, screenWidth } from "../../utils";
import { appImages } from "../../themes/imageConstant";
import { appColors } from "../../themes/colorConst";
import {
  AttemptScoreContainer,
  AttemptValContainer,
  AttemptValue,
  BottomBtnContainer,
  BottomContainer,
  CrossImg,
  MainContainer,
  PuzzleContainer,
  ScoreSpan,
  SectionContainer,
  StarImg,
  Step1Top,
  Step2Bottom,
  TextWrapper,
  AttemptLabel
} from "./styles";
import { useDispatch } from "react-redux";
import { updateGamePlayStates } from "../../redux/actions/game";

const BotSolutions = (props) => {

  const dispatch = useDispatch()

  const botStepsFromStorage = JSON.parse(localStorage.getItem('botSteps')) || []

  // console.log("this is Solutions = ", botStepsFromStorage)
  const {
    isResultVisible,
    handleCloseBotScreen,
    finalWord,
    initialWord,
    yourResult,
    botSteps,
    botStepsCount
  } = props;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [wordArr, setWordArr] = React.useState(["S", "P", "A", "C", "E"]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    return () => {
      return () => {
        dispatch(updateGamePlayStates(false, 'botStepsSuccess'))
      }
    }
  }, [])

  const list = (anchor) => (
    <OnboardingWrapper>
      <ContentWrapper id={"contentContainer"}>
        <MainContainer
          cstmpadding={
            screenWidth > maximumTabletWidth
              ? "15px 0"
              : (15 / screenWidth) * 100 + "vh 0"
          }
        >
          <SectionContainer postionrelative={"relative"}>
            <TextWrapper
              fontsize={
                screenWidth > maximumTabletWidth
                  ? "28px"
                  : (28 / screenWidth) * 100 + "vw"
              }
              fontfamily={"poppins500"}
              color={appColors.greyTextColor}
            ></TextWrapper>
            <CrossImg
              onClick={() => handleCloseBotScreen()}
              src={appImages.cross}
            />
          </SectionContainer>
          <SectionContainer>
            <TextWrapper
              fontsize={
                screenWidth > maximumTabletWidth
                  ? "28px"
                  : (28 / screenWidth) * 100 + "vw"
              }
              fontfamily={"poppins500"}
              color={appColors.greyTextColor}
            >
              Change Bot’s Solution
            </TextWrapper>
          </SectionContainer>
          <SectionContainer>
            <img src={appImages.bot} />
          </SectionContainer>
          <SectionContainer>
            <TextWrapper
              fontsize={
                screenWidth > maximumTabletWidth
                  ? "20px"
                  : (20 / screenWidth) * 100 + "vw"
              }
              fontfamily={"poppins400"}
              color={appColors.greyTextColor}
              cstmpadding={`${screenWidth > maximumTabletWidth
                  ? "0 15px"
                  : `0 ${(15 / screenWidth) * 100}vw`
                }`}
              textalign={"center"}
            >
              I managed to find a way to change it in <ScoreSpan>{botStepsCount || 0}</ScoreSpan>!
            </TextWrapper>
          </SectionContainer>
        </MainContainer>
        <BottomContainer>
          <Step1Top
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
            alignBottom
          >
            {
              initialWord
              &&
              initialWord.length > 0
              &&
              initialWord.map((item, index) => {
                return (
                  <NextBtn
                    key={index}
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteColor}
                    boxshadow
                    cursor={'default'}
                  >
                    <TextBtn
                      color={appColors.greyTextColor}
                      fontSize={
                        screenWidth > maximumTabletWidth
                          ? "36px"
                          : (36 / screenWidth) * 100 + "vw"
                      }
                    >
                      {item || ""}
                    </TextBtn>
                  </NextBtn>
                )
              })
            }
          </Step1Top>
          <AttemptScoreContainer
            // padding={
            //   screenWidth > maximumTabletWidth
            //     ? "85px"
            //     : (20 / screenWidth) * 100 + "vw"
            // }
            width={screenWidth > maximumTabletWidth ? "340px" : (340 / screenWidth) * 100 + "vw"}
          >
            {(botSteps && botSteps?.length > 0) ?

              botSteps.map((item, index) => {
                return (
                  <AttemptValContainer key={index}>
                    <AttemptLabel>{item && item.toUpperCase() || ''}</AttemptLabel>
                    <AttemptValue>{index + 1}</AttemptValue>
                  </AttemptValContainer>
                )
              }) : 
              botStepsFromStorage && botStepsFromStorage?.length > 0 &&
              botStepsFromStorage.map((item, index) => {
                return (
                  <AttemptValContainer key={index}>
                    <AttemptLabel>{item && item.toUpperCase() || ''}</AttemptLabel>
                    <AttemptValue>{index + 1}</AttemptValue>
                  </AttemptValContainer>
                )
              })
            }
          </AttemptScoreContainer>
          <Step2Bottom
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
            cstmHeight={"10%"}
          >
            {
              finalWord
              &&
              finalWord.length > 0
              &&
              finalWord.map((item, index) => {
                return (
                  <NextBtn
                  key={index}
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? "60px"
                  : (60 / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? "60px"
                  : (60 / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            // marginRight={marginRight}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "36px"
                    : (36 / screenWidth) * 100 + "vw"
                }
              >
                {item || ""}
              </TextBtn>
            </NextBtn>
                )
              })
            }
          </Step2Bottom>
        </BottomContainer>

        {/* <img
            src={appImages.cross}
            height={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
            width={screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"}
          /> */}
      </ContentWrapper>
    </OnboardingWrapper>
  );

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <Drawer
          anchor={isResultVisible ? "bottom" : "top"}
          // anchor={'bottom'}
          open={isResultVisible}
          onClose={() => {
            // toggleDrawer(anchor, false)
          }}
        >
          {list("bottom")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default React.memo(BotSolutions);
