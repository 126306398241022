import * as React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { PopoverComponent } from './styles';

const PopoverCommon = (props) => {

  const {
    isFilterPopupVisible,
    id,
    handleCloseFilterPopup,
    popOverContent
  } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  return (
    <div>
      <PopoverComponent
        id={id}
        open={isFilterPopupVisible}
        anchorEl={isFilterPopupVisible}
        onClose={handleCloseFilterPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',

        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

      >
        {popOverContent}
      </PopoverComponent>
    </div>
  )
}

export default React.memo(PopoverCommon);
