
import { toastCommon } from "../utils";
import APIKit from "./apiKit";

// headers
const jsonHeader = { "access-control-allow-origin": "*", "Content-Type": "application/json", Accept: "application/json" }
const formDataHeader = { "access-control-allow-origin": "*", "Content-Type": "multipart/form-data", Accept: "application/json" }

// common api handler
const apiHandlerFunction = (handler) => {
  
 
    
    return handler

      .then(result => { return { success: true, status: result.status, message: result.data.message, data: result?.data } })

      .catch((err) => {
        
        if (err?.response?.status) {
          
          if (err?.response?.status >= 400 && err?.response?.status < 500) {
            return { success: false, status: err.response.request.status, message: err?.response?.data?.message }
          }
          else return { success: false, status: 500, message: "Something went wrong." }
        }
        else return { success: false, status: 500, message: "Something went wrong." }
      })


}

// Methods
export const Method = {

  // post method with json
  post: (url, body, formData) =>  {

    return window.navigator.onLine ? apiHandlerFunction(APIKit.post(url, body, { headers: formData ? formDataHeader : jsonHeader })) : toastCommon.call(true, "Please check your internet connection.", 'error');
  },

  // get method
  get: (url) => {
    return window.navigator.onLine ? apiHandlerFunction(APIKit.get(url, { headers: jsonHeader })) : toastCommon.call(true, "Please check your internet connection.", 'error');
  },

  // put method with json
  put: (url, body, formData) => {
    
    return apiHandlerFunction(APIKit.put(url, body, { headers: formData ? formDataHeader : jsonHeader }))
  },

  // delete method
  delete: (url) => apiHandlerFunction(APIKit.delete(url, { headers: jsonHeader })),
};
