export const appImages = {
    // Images
    bannerImg: require('../assets/images/bannerImg.svg').default,
    bannerImg2: require('../assets/images/bannerImg2.svg').default,
    bannerImg3: require('../assets/images/bannerImg3.svg').default,
    bannerImg4: require('../assets/images/bannerImg4.svg').default,
    bannerImg5: require('../assets/images/bannerImg5.svg').default,
    bannerImg6: require('../assets/images/bannerImg6.svg').default,
    bannerImg7: require('../assets/images/bannerImg7.svg').default,
    gameBanner1: require('../assets/images/gameBanner1.svg').default,
    gameBanner2: require('../assets/images/gameBanner2.svg').default,
    gameBanner3: require('../assets/images/gameBanner3.svg').default,
    gameBanner4: require('../assets/images/gameBanner4.svg').default,
    gameBanner5: require('../assets/images/gameBanner5.svg').default,
    gameBanner6: require('../assets/images/gameBanner6.svg').default,
    gameBanner7: require('../assets/images/gameBanner7.svg').default,
    arrowRope: require('../assets/images/arrowRope.png'),

    // Icons
    nextArrow: require('../assets/icons/nextArrow.png'),
    loadIcon: require('../assets/icons/loadIcon.png'),
    loadGrey: require('../assets/icons/loadGrey.png'),
    bars: require('../assets/icons/bars.png'),
    queestion: require('../assets/icons/queestion.png'),
    logoBorder: require('../assets/icons/logoBorder.png'),
    users: require('../assets/icons/users.png'),
    plus: require('../assets/icons/plus.svg').default,
    tick: require('../assets/icons/tick.svg').default,
    crossWhite: require('../assets/icons/crossWhite.svg').default,
    cross: require('../assets/icons/cross.svg').default,
    star: require('../assets/icons/star.svg').default,
    bot: require('../assets/icons/bot.svg').default,
    info: require('../assets/icons/info.svg').default,
    starOutline: require('../assets/icons/starOutline.svg').default,
    arrowDown: require('../assets/icons/arrowDown.svg').default,
    arrowUp: require('../assets/icons/arrowUp.svg').default,
}