import styled, { css } from "styled-components";

export const KeyboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ced2d9;
  // padding: 5px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  z-index: 999;
  position: absolute;
  width: 100%;
  bottom: 0;
  ${({ height }) => height && css`
    height: ${height};
  `}
  ${({ gap }) => gap && css`
  gap: ${gap};
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => gap && css`
  gap: ${gap};
  `}
`;

export const KeyButton = styled.button`
  border-radius: 5px;
  color: ${({ disabled }) => (disabled ? "#FFF" : "#000")};
  background-color: ${({ disabled }) => (disabled ? "#646464" : "#FFFFFF")};
  border: none;
  padding: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  ${({ width }) => width && css`
  width: ${width};
  `}
  ${({ height }) => height && css`
  height: ${height};
  `}
  ${({ fontsize }) => fontsize && css`
  font-size: ${fontsize};
  `}
`;
