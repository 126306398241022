import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BoxContainer, CrossImg, MainContainer, ModalContainer, SectionContainer, StarContainer, StarImg, StarRowContainer, StarRowLabel, StarRowValue, TextWrapper } from './styles';
import { maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';



const ModalCommon = (props) => {

  const {
    isRatingModalVisible,
    handleCloseModal
  } = props

  return (
    <ModalContainer
        open={isRatingModalVisible}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxContainer>
        <MainContainer cstmpadding={screenWidth > maximumTabletWidth ? "10px 0" : (10 / screenWidth) * 100 + "vh 0"}>
        <SectionContainer postionrelative={'relative'} textalign={'center'}>
              <TextWrapper
              fontsize={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth) * 100 + "vw"}
              fontfamily={'poppins400'}
              color={appColors.greyTextColor}
              >
                Star Ratings
              </TextWrapper>
              <CrossImg
                onClick={() => handleCloseModal()}
                src={appImages.cross}
              />
            </SectionContainer>
            <SectionContainer>
              <TextWrapper
                fontsize={screenWidth > maximumTabletWidth ? "18px" : (18 / screenWidth) * 100 + "vw"}
                fontfamily={'poppins400'}
                color={appColors.greyTextColor}
                textalign={'center'}
              >
                Star ratings are calculated based on how close your solution is to the optimum number of changes needed to complete the game
              </TextWrapper>
            </SectionContainer>
            <SectionContainer>
              <StarContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    Optimum
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  </StarRowValue>
                </StarRowContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    1 away
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  </StarRowValue>
                </StarRowContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    2 away
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  </StarRowValue>
                </StarRowContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    3 away
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  <StarImg
                    src={appImages.star}
                  />
                  </StarRowValue>
                </StarRowContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    4+ away
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.star}
                  />
                  </StarRowValue>
                </StarRowContainer>
                <StarRowContainer>
                  <StarRowLabel>
                    Didn’t Solve
                  </StarRowLabel>
                  <StarRowValue>
                  <StarImg
                    src={appImages.starOutline}
                  />
                  </StarRowValue>
                </StarRowContainer>
              </StarContainer>
            </SectionContainer>
            </MainContainer>
       
        </BoxContainer>
      </ModalContainer>
  )
}

export default ModalCommon
