import styled, { css, keyframes } from 'styled-components'
import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { Button, MobileStepper, Backdrop } from '@mui/material';

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    
`

export const ContentWrapper = styled.div`
width: 500px;
height: 100%;

@media (max-width: ${maximumTabletWidth}px) {
  width: 100%;
}

`

export const BannerWrapper = styled.div`
  height: 55%;
  position: relative;
`

export const NextBtn = styled(Button)`
  text-transform: none!important;
  min-width: auto!important;
  border-radius: 8px!important;
  padding: 0!important;
  box-shadow: none!important;
  ${({ boxshadow}) => boxshadow && css`
    box-shadow: -3px 3px 15px 1px #5E5E5EB2!important;
  `}
  ${({ boxshadowactionbtn}) => boxshadowactionbtn && css`
    box-shadow: 0px 6px 10px 0px #5E5E5EB2!important;
  `}
  ${({ borderradius }) => borderradius && css`
  border-radius: ${borderradius}!important;
  `}
  ${({ height, width, backgroundcolor }) => css`
    height: ${height};
    width: ${width};
    background-color: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) => border && css`
    border: ${border}!important;
    border-color: ${borderColor}!important;
    border-style: ${borderStyle && borderStyle}!important;
  `}
`

export const MoveBtn = styled(motion.div)`
  display: flex;
  align-items: center;
  ${({ contentCenter }) => contentCenter && css`
    justify-content: center;
  `}
  ${({ height }) => height && css`
    height: ${height};
  `}
`

export const LoadImg = styled.img`
  ${({ height, width, transform }) => css`
  height: ${height};
  width: ${width};
  transform: ${transform};
  // transition: transform 0.3s ease;
  `}
`

// Define keyframes for the rotation animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const BlurBackDrop = styled(Backdrop)`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.8); // Slightly white with transparency
backdrop-filter: blur(0.5px);
display: flex;
justify-content: center;
align-items: center;
z-index: 1301; // Ensure it is above other elements
`

// Styled image component with rotation animation
export const LoaderImage = styled.img`
  width: 54px;
  height: 50px;
  animation: ${spin} 1.15s linear infinite;
`;