import React, { useEffect, useState } from 'react'
import { AttemptLabel, AttemptScoreContainer, AttemptValContainer, AttemptValue, LoadImg, MoveBtn, NextBtn, Step1Bottom, Step1Top, Step2Bottom, StepContainer, StepContent, TextBtn } from './styles'
import { maximumTabletWidth, screenWidth } from '../../utils'
import { appColors } from '../../themes/colorConst'
import { appImages } from '../../themes/imageConstant'
import { useAnimation } from 'framer-motion'

export const Step7 = (props) => {

  const buttonTopContainer = useAnimation()
  const buttonBottomContainer = useAnimation()
  const attempt1 = useAnimation()
  const attempt2 = useAnimation()
  const attempt3 = useAnimation()
  const attempt4 = useAnimation()
  const bottomText = useAnimation()
  const duration = 1
  const xValue = 1; // Set your desired x value here
  const yValue = -1; // Set your desired x value here

  const [animationStarted, setAnimationStarted] = React.useState(false);


  const animate = async () => {
    buttonTopContainer.mount()
    await buttonTopContainer.start({
      y: 0,
      transition: { duration: duration },
    })

    // Add some delay before starting the attempt 1 animation
    // await new Promise((resolve) => setTimeout(resolve, 1 * 500));

    attempt1.mount()
    buttonBottomContainer.mount()
    await Promise.all([
      attempt1.start({
        opacity: [0.1, 0.3, 1],
        transition: { duration: duration / 4, ease: "easeInOut" },
      }),
      // attempt1.start({
      //   x: [-1, 0],
      //   y: [1, 0],
      //   transition: { duration: 0.5, ease: "easeInOut" },
      // }),
      // buttonBottomContainer.start({
      //   y: [-1, 0],
      //   transition: { duration: 0.5, ease: "easeInOut" },
      // })
    ])



    while (true) {

      // Add some delay before starting the attempt 2 animation
      await new Promise((resolve) => setTimeout(resolve, 1 * 500));

      attempt2.mount()
      attempt1.mount()
      buttonBottomContainer.mount()
      await Promise.all([
        attempt2.start({
          opacity: [0.1, 0.3, 1],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
        // attempt2.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt1.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // buttonBottomContainer.start({
        //   y: [-1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // })
      ])

      // Add some delay before starting the attempt 3 animation
      await new Promise((resolve) => setTimeout(resolve, 1 * 500));

      attempt3.mount()
      attempt1.mount()
      attempt2.mount()
      buttonBottomContainer.mount()
      await Promise.all([
        attempt3.start({
          opacity: [0.1, 0.3, 1],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
        // attempt3.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt1.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt2.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // buttonBottomContainer.start({
        //   y: [-1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // })
      ])

      // Add some delay before starting the attempt 4 animation
      await new Promise((resolve) => setTimeout(resolve, 1 * 500));

      attempt4.mount()
      attempt1.mount()
      attempt2.mount()
      attempt3.mount()
      buttonBottomContainer.mount()
      await Promise.all([
        attempt4.start({
          opacity: [0.1, 0.3, 1],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
        // attempt4.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt1.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt2.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // attempt3.start({
        //   x: [-1, 0],
        //   y: [1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // }),
        // buttonBottomContainer.start({
        //   y: [-1, 0],
        //   transition: { duration: 0.5, ease: "easeInOut" },
        // })
      ])

      // add some delay before starting the background green animation
      await new Promise((resolve) => setTimeout(resolve, 1 * 500));
      setAnimationStarted(true)

      // add some delay before initialize all animations
      await new Promise((resolve) => setTimeout(resolve, 1 * 1000));

      attempt4.mount()
      attempt2.mount()
      attempt3.mount()
      await Promise.all([
        setAnimationStarted(false),
        attempt2.start({
          opacity: [0.3, 0.1, 0],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
        attempt3.start({
          opacity: [0.3, 0.1, 0],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
        attempt4.start({
          opacity: [0.3, 0.1, 0],
          transition: { duration: duration / 4, ease: "easeInOut" },
        }),
      ])
    }
  }

  useEffect(() => {
    setTimeout(() => {
      animate()
    }, 1000)

    return () => {
      buttonTopContainer.stop();
      buttonBottomContainer.stop();
      attempt1.stop();
      attempt2.stop();
      attempt3.stop();
      attempt4.stop();
    };
  }, [])

  return (
    <StepContainer cstmHeight={'100%'}>
      <StepContent
        gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        cstmHeight={'100%'}
      >

        <Step1Top
          cstmHeight={'25%'}
          alignBottom
        >
          <MoveBtn
            initial={{ opacity: 1, y: 100 }}
            animate={buttonTopContainer}
            contentCenter={"center"}
            gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                S
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                P
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                A
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                C
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                E
              </TextBtn>
            </NextBtn>

          </MoveBtn>
        </Step1Top>
        <AttemptScoreContainer
          cstmHeight={'45%'}
          padding={screenWidth > maximumTabletWidth ? "10px 0" : (10 / screenWidth * 100) + "vh 0"}
        >

          <AttemptValContainer>
            <MoveBtn
              initial={{ opacity: 0, x: 0, y: 0 }}
              animate={attempt1}
              width
            >
              <AttemptLabel>
                SPACED
              </AttemptLabel>
              <AttemptValue>
                1
              </AttemptValue>
            </MoveBtn>
          </AttemptValContainer>
          <AttemptValContainer>
            <MoveBtn
              initial={{ opacity: 0 }}
              animate={attempt2}
              width
            >
              <AttemptLabel>
                PACED
              </AttemptLabel>
              <AttemptValue>
                2
              </AttemptValue>
            </MoveBtn>
          </AttemptValContainer>
          <AttemptValContainer>
            <MoveBtn
              initial={{ opacity: 0, x: 0, y: 0 }}
              animate={attempt3}
              width
            >
              <AttemptLabel>
                FACED
              </AttemptLabel>
              <AttemptValue>
                3
              </AttemptValue>
            </MoveBtn>
          </AttemptValContainer>
          <AttemptValContainer>
            <MoveBtn
              initial={{ opacity: 0, x: 0, y: 0 }}
              animate={attempt4}
              width
            >
              <AttemptLabel>
                DECAF
              </AttemptLabel>
              <AttemptValue>
                4
              </AttemptValue>
            </MoveBtn>
          </AttemptValContainer>
        </AttemptScoreContainer>
        <Step2Bottom
          // gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          cstmHeight={'30%'}
        >
          <MoveBtn
            initial={{ opacity: 1, y: 0, x: 0 }}
            animate={buttonBottomContainer}
            alignitem={'flex-start'}
            gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={animationStarted ? appColors.successBtnColor : appColors.whiteColor}
              boxshadow
              cursor={'default'}
            // marginRight={marginRight}
            >
              <TextBtn
                color={animationStarted ? appColors.whiteText : appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                D
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={animationStarted ? appColors.successBtnColor : appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={animationStarted ? appColors.whiteText : appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                E
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={animationStarted ? appColors.successBtnColor : appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={animationStarted ? appColors.whiteText : appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                C
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={animationStarted ? appColors.successBtnColor : appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={animationStarted ? appColors.whiteText : appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                A
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
              backgroundcolor={animationStarted ? appColors.successBtnColor : appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={animationStarted ? appColors.whiteText : appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
              >
                F
              </TextBtn>
            </NextBtn>
          </MoveBtn>
        </Step2Bottom>
      </StepContent>
    </StepContainer>
  )
}

export default React.memo(Step7)
