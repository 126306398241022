import styled, { css } from "styled-components";
import { Button, MobileStepper } from "@mui/material";

import { maximumTabletWidth, screenWidth } from "../../utils/index";
import { appColors } from "../../themes/colorConst";
import { motion } from "framer-motion";

export const OnboardingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 500px;
  height: 100%;

  @media (max-width: ${maximumTabletWidth}px) {
    width: 100%;
  }
`;

export const BannerWrapper = styled.div`
  height: 70%;
  position: relative;
  ${({ cstmheight }) =>
    cstmheight &&
    css`
      height: ${cstmheight};
    `}

  ${({ background }) =>
    background &&
    css`
      background: url(${background});
    `}
  
  ${({ backgroundposition }) =>
    backgroundposition &&
    css`
      background-position: ${backgroundposition};
    `}
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BottomCWrapper = styled.div`
  height: 30%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  ${({ cstmheight }) =>
    cstmheight &&
    css`
      height: ${cstmheight}!important;
    `}
`;

export const AttemptLabel = styled.div`
  width: 85%;
  display: flex;
  justify-content: center;
  font-size: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins700;
  ${({ fontfamily }) =>
    fontfamily &&
    css`
      font-family: ${fontfamily};
    `}
  padding-left: ${screenWidth > maximumTabletWidth
    ? "45px"
    : (45 / screenWidth) * 100 + "vw"};
`;

export const AttemptScoreContainer = styled.div`
  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      max-height: ${cstmHeight};
      overflow: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none; 
    scrollbar-width: none; 

    &::-webkit-scrollbar {
      display: none; 
    }
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const AttemptValContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const AttemptValue = styled.div`
  width: 15%;
  display: flex;
  justify-content: end;
  font-size: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
  font-family: poppins500;
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 95%;
`;

export const LeftOptionsFirst = styled.div`
  height: ${screenWidth > maximumTabletWidth
    ? "23px"
    : (23 / screenWidth) * 100 + "vw"};
  width: ${screenWidth > maximumTabletWidth
    ? "23px"
    : (23 / screenWidth) * 100 + "vw"};
  border: 2.8px solid #5E5E5E;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const NameContainer = styled.div`
  font-family: poppins600;
  fontsize: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
`;

export const NextBtn = styled(Button)`
  text-transform: none !important;
  min-width: auto !important;
  border-radius: 8px !important;
  ${({ borderradius }) =>
    borderradius &&
    css`
      border-radius: ${borderradius}!important;
    `}
  ${({ height, width, backgroundcolor }) => css`
    height: ${height};
    width: ${width};
    background-color: ${backgroundcolor}!important;
  `}
  ${({ border, borderColor, borderStyle }) =>
    border &&
    css`
      border: ${border}!important;
      border-color: ${borderColor}!important;
      border-style: ${borderStyle && borderStyle}!important;
    `}

  ${({ boxshadow }) =>
    boxshadow &&
    css`
      box-shadow: ${boxshadow}!important;
    `}

    ${({ cursor }) => cursor && css`
    cursor: ${cursor}!important;
  `}
`;

export const OptionsFirstImg = styled.img`
  height: ${screenWidth > maximumTabletWidth
    ? "14px"
    : (14 / screenWidth) * 100 + "vw"};
  width: ${screenWidth > maximumTabletWidth
    ? "14px"
    : (14 / screenWidth) * 100 + "vw"};
`;

export const RightOptionsFirst = styled.div`
  ${({ height, width }) => css`
    height: ${height};
    width: ${width};
  `}
`;
export const RightOptionsFirstImg = styled.img`
  height: 100%;
  width: 100%;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ gap }) => css`
    gap: ${gap};
  `};
`;

export const StatusIconContainer = styled.div`
  display: flex;
  alig-items: center;
  justify-content: center;
  height: ${screenWidth > maximumTabletWidth
    ? "65px"
    : (65 / screenWidth) * 100 + "vw"};
  width: ${screenWidth > maximumTabletWidth
    ? "65px"
    : (65 / screenWidth) * 100 + "vw"};
  ${({ bgcolor }) => css`
    background-color: ${bgcolor};
  `};
  border-radius: 50%;
`;

export const StatusMessage = styled.div`
  font-family: poppins400;
  fontsize: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      height: ${cstmHeight};
      justify-content: space-around;
    `}
  ${({ aligncenter }) =>
    aligncenter &&
    css`
      align-items: ${aligncenter};
    `}
`;

export const Step1Top = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => css`
    gap: ${gap};
  `}
  ${({ cstmHeight }) =>
    cstmHeight &&
    css`
      height: ${cstmHeight};
    `}
  ${({ alignBottom }) =>
    alignBottom &&
    css`
      display: flex;
      align-items: end;
    `}
  ${({ positionrelative }) =>
    positionrelative &&
    css`
      position: ${positionrelative};
    `}

    ${({ paddingbottom }) =>
      paddingbottom &&
      css`
        padding-bottom: ${paddingbottom};
      `}
`;

export const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${screenWidth > maximumTabletWidth
    ? "0px 0 30px 0"
    : `${(5 / screenWidth) * 100}vh 0 ${(10 / screenWidth) * 100}vh 0`};
  ${({ cstmwidth }) => css`
    width: ${cstmwidth};
  `}
`;

export const TopOptionsLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${screenWidth > maximumTabletWidth
    ? "5px"
    : (5 / screenWidth) * 100 + "vw"};
  color: ${appColors.greyTextColor};
  font-size: ${screenWidth > maximumTabletWidth
    ? "20px"
    : (20 / screenWidth) * 100 + "vw"};
`;

export const TopOptionsRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${screenWidth > maximumTabletWidth
    ? "15px"
    : (15 / screenWidth) * 100 + "vw"};
`;

export const TextBtn = styled.span`
  font-family: poppins700;
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily}!important;
    `}
  ${({ color, fontSize }) => css`
    color: ${color};
    font-size: ${fontSize};
  `}
`;
export const BottomBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth
    ? "5px"
    : (5 / screenWidth) * 100 + "vw"};
`;
export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  ${({ postionrelative }) =>
    postionrelative &&
    css`
      position: ${postionrelative};
    `}
`;
export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color, marginTop }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) =>
    cstmpadding &&
    css`
      padding: ${cstmpadding};
    `}
${({ textalign }) =>
    textalign &&
    css`
      text-align: ${textalign};
    `}
${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`;

export const ResultText = styled.span`
  font-family: "poppins700";
`;