import styled, { css } from 'styled-components'
import { Button, MobileStepper } from '@mui/material';

import { maximumTabletWidth, screenWidth } from '../../utils/index'
import { appColors } from '../../themes/colorConst'
import { motion } from 'framer-motion';
import { appImages } from '../../themes/imageConstant';




export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ gap }) => gap && css`
    gap: ${gap};
  `} 
  ${({ postionrelative }) => postionrelative && css`
    position: ${postionrelative};
  `} 
  ${({ paddingleftcstm }) => paddingleftcstm && css`
    padding-left: ${screenWidth > maximumTabletWidth ? "10px" : (10 /screenWidth * 100)+"vw"};
  `} 
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `} 
  ${({ visibiltyhidden }) => visibiltyhidden && css`
    visibility: hidden!important;
  `} 
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${screenWidth > maximumTabletWidth ? "20px" : (8 /screenWidth * 100)+"vw"};
  ${({ gap }) => css`
    gap: ${gap};
  `}  
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}  
`

export const TextWrapper = styled.div`
  ${({ fontsize, fontfamily, color }) => css`
    font-size: ${fontsize};
    font-family: ${fontfamily};
    color: ${color};
  `}
  ${({ cstmpadding }) => cstmpadding && css`
    padding: ${cstmpadding};
  `}
  ${({ textalign }) => textalign && css`
    text-align: ${textalign};
  `}
  ${({ positionrelative }) => positionrelative && css`
    position: ${positionrelative};
  `}
`

export const PuzzleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 /screenWidth * 100)+"vw"};
`

export const BottomBtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${screenWidth > maximumTabletWidth ? "5px" : (5 /screenWidth * 100)+"vw"};
`

export const CrossImg = styled.img`
  position: absolute;
  right: 0;
  padding-right: ${screenWidth > maximumTabletWidth ? "20px" : (20 /screenWidth * 100)+"vw"};
  height: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  width: ${screenWidth > maximumTabletWidth ? "24px" : (24 /screenWidth * 100)+"vw"};
  cursor: pointer;
`

export const StarImg = styled.img`
  height: ${screenWidth > maximumTabletWidth ? "47px" : (47 / screenWidth * 100) + "vw"};
  width: ${screenWidth > maximumTabletWidth ? "49px" : (49 / screenWidth * 100) + "vw"};
`

export const ResultText = styled.span`
  font-family: "poppins700";
`;