import React, { useCallback, useEffect, useState } from "react";
import {
  GameBtnContainer,
  LoadImg,
  MoveBtn,
  NextBtn,
  Step1Bottom,
  Step1Top,
  Step2Bottom,
  StepContainer,
  StepContent,
  TextBtn,
} from "./styles";
import { maximumTabletWidth, screenWidth } from "../../utils";
import { appColors } from "../../themes/colorConst";
import { appImages } from "../../themes/imageConstant";
import { useAnimation } from "framer-motion";

export const Step4 = (props) => {
  
  const firLetterBorderContainer = useAnimation();
  const intialWordContainer = useAnimation();
  const finalWordContainer = useAnimation();

  const [wordArr, setWordArr] = useState(["P", "A", "C", "E", "D"]);
  const [wordArr2, setWordArr2] = useState(["S", "P", "A", "C", "E", "D"]);


  const animateWords = useCallback( async () => {

    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 500));

      firLetterBorderContainer.mount()
      await firLetterBorderContainer.start({
        border: '1px solid',
        borderColor: appColors.borderColor,
        borderStyle: 'dashed'
      })

      await new Promise((resolve) => setTimeout(resolve, 500));
      intialWordContainer.mount()
      finalWordContainer.mount()
      await Promise.all([
        intialWordContainer.start({
          opacity: [1,0.5, 0.1,0],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        finalWordContainer.start({
          opacity: [0,0.1,0.5, 1],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        
      ]);
      await new Promise((resolve) => setTimeout(resolve, 500));
      firLetterBorderContainer.mount()
      await firLetterBorderContainer.start({
        border: 'none',
        borderColor: 'none',
        borderStyle: 'none'
      })

      intialWordContainer.mount()
      finalWordContainer.mount()
      await Promise.all([
        finalWordContainer.start({
          opacity: [1,0.5, 0.1,0],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
        intialWordContainer.start({
          opacity: [0,0.1,0.5, 1],
          transition: { duration: 0.5, ease: 'easeInOut' },
        }),
      ])

    }
  }, [])

  useEffect(() => {
    // setTimeout(() => {
      animateWords();
    // }, 1000)

    return () => {
      intialWordContainer.stop();
      finalWordContainer.stop();
      firLetterBorderContainer.stop();
    };
  }, []);

  const WordDisplay = ({ animate }) => {
    return (
      <MoveBtn
      animate={animate}
      contentCenter={"center"}
      initial={{ opacity: 0 }}
      gap={
        screenWidth > maximumTabletWidth
          ? "10px"
          : (10 / screenWidth) * 100 + "vw"
      }
    >
      {
         wordArr.map((item, index) => {
          let wordDimension = 60 
          return (
         
            <NextBtn
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? `${wordDimension}px`
                  : (wordDimension / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? `${wordDimension}px`
                  : (wordDimension / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "36px"
                    : (36 / screenWidth) * 100 + "vw"
                }
              >
                {item}
              </TextBtn>
            </NextBtn>
          
        )})
      }
    </MoveBtn>
    )
  }

  const WordDisplay1 = ({ animate }) => {
    return (
      <MoveBtn
      animate={animate}
      contentCenter={"center"}
      initial={{ opacity: 1 }}
      gap={
        screenWidth > maximumTabletWidth
          ? "10px"
          : (10 / screenWidth) * 100 + "vw"
      }
    >
      {
         wordArr2.map((item, index) => {
          let wordDimension = 50
          return (
         
            <NextBtn
              initial={{ border: 'none', borderColor: 'none', borderStyle: 'none'}}
              animate={index == 0 && firLetterBorderContainer}
              variant="contained"
              size="small"
              height={
                screenWidth > maximumTabletWidth
                  ? `${wordDimension}px`
                  : (wordDimension / screenWidth) * 100 + "vw"
              }
              width={
                screenWidth > maximumTabletWidth
                  ? `${wordDimension}px`
                  : (wordDimension / screenWidth) * 100 + "vw"
              }
              backgroundcolor={appColors.whiteColor}
              boxshadow
              cursor={'default'}
              // border={(showBorder && index == 0) ? "1px solid" : 'false'}
              // borderColor={(showBorder && index == 0) ? appColors.borderColor : 'false'}
              // borderStyle={(showBorder && index == 0) ? "dashed" : 'false'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={
                  screenWidth > maximumTabletWidth
                    ? "36px"
                    : (36 / screenWidth) * 100 + "vw"
                }
              >
                {item}
              </TextBtn>
            </NextBtn>
          
        )})
      }
      </MoveBtn>
    )
  }


  return (
    <>
      <StepContainer>
        <StepContent
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
          justifyend
        >
          <Step1Top
            positionabsolute
          >
              <WordDisplay words={wordArr} animate={finalWordContainer} /> 
            
       
          </Step1Top>
          <Step1Top
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
          >
          <WordDisplay1 words={wordArr2} animate={intialWordContainer} />
          </Step1Top>
        </StepContent>
      </StepContainer>
      <GameBtnContainer>
        <Step1Top
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
        >
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Add
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.greyBtnColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Remove
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Change
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Mix Up
            </TextBtn>
          </NextBtn>
        </Step1Top>
      </GameBtnContainer>
    </>
  );
};

export default React.memo(Step4);