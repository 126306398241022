export const types = {
    IS_USER_EXIST_LOAD: 'IS_USER_EXIST_LOAD',
    IS_USER_EXIST_SUCCESS: 'IS_USER_EXIST_SUCCESS',
    IS_USER_EXIST_FAIL: 'IS_USER_EXIST_FAIL',

    GET_WORD_LOAD: 'GET_WORD_LOAD',
    GET_WORD_SUCCESS: 'GET_WORD_SUCCESS',
    GET_WORD_FAIL: 'GET_WORD_FAIL',

    COMPLETE_ONBOARDING_LOAD: 'COMPLETE_ONBOARDING_LOAD',
    COMPLETE_ONBOARDING_SUCCESS: 'COMPLETE_ONBOARDING_SUCCESS',
    COMPLETE_ONBOARDING_FAIL: 'COMPLETE_ONBOARDING_FAIL',

    VALIDATE_WORD_LOAD: 'VALIDATE_WORD_LOAD',
    VALIDATE_WORD_SUCCESS: 'VALIDATE_WORD_SUCCESS',
    VALIDATE_WORD_FAIL: 'VALIDATE_WORD_FAIL',

    YOUR_RESULT_LOAD: 'YOUR_RESULT_LOAD',
    YOUR_RESULT_SUCCESS: 'YOUR_RESULT_SUCCESS',
    YOUR_RESULT_FAIL: 'YOUR_RESULT_FAIL',

    BOT_STEPS_LOAD: 'BOT_STEPS_LOAD',
    BOT_STEPS_SUCCESS: 'BOT_STEPS_SUCCESS',
    BOT_STEPS_FAIL: 'BOT_STEPS_FAIL',

    YOUR_PERFORMANCE_LOAD: 'YOUR_PERFORMANCE_LOAD',
    YOUR_PERFORMANCE_SUCCESS: 'YOUR_PERFORMANCE_SUCCESS',
    YOUR_PERFORMANCE_FAIL: 'YOUR_PERFORMANCE_FAIL',

    GET_TIME_LOAD: 'GET_TIME_LOAD',
    GET_TIME_SUCCESS: 'GET_TIME_SUCCESS',
    GET_TIME_FAIL: 'GET_TIME_FAIL',

    GET_PROFILE_LOAD: 'GET_PROFILE_LOAD',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',

    USER_EVENTS_LOAD: 'USER_EVENTS_LOAD',
    USER_EVENTS_SUCCESS: 'USER_EVENTS_SUCCESS',
    USER_EVENTS_FAIL: 'USER_EVENTS_FAIL',

    UPDATE_GAME_PLAY_STATES: 'UPDATE_GAME_PLAY_STATES',
}