import React, { useEffect, useState } from "react";
import {
  GameBtnContainer,
  LoadImg,
  MoveBtn,
  NextBtn,
  Step1Bottom,
  Step1Top,
  Step2Bottom,
  StepContainer,
  StepContent,
  TextBtn,
} from "./styles";
import { maximumTabletWidth, screenWidth } from "../../utils";
import { appColors } from "../../themes/colorConst";
import { appImages } from "../../themes/imageConstant";
import { useAnimation } from "framer-motion";

export const Step5 = (props) => {
  const intialWordContainer = useAnimation();
  const finalWordContainer = useAnimation();

  const animateWords = async () => {
    while (true) {
      await new Promise((resolve) => setTimeout(resolve, 500));

      intialWordContainer.mount()
      finalWordContainer.mount()
      await Promise.all([
        intialWordContainer.start({
          opacity: [1,0.5, 0.1,0],
          transition: { duration: 0.4, ease: 'easeInOut' },
        }),
        
      ]);

      await finalWordContainer.start({
        opacity: [0,0.1,0.5, 1],
        transition: { duration: 0.4, ease: 'easeInOut', delay: 0.5 },
      });
      await finalWordContainer.start({
        opacity: [1,0.5, 0.1,0],
        transition: { duration: 0.4, ease: 'easeInOut', delay: 0.5 },
      })
      await intialWordContainer.start({
        opacity: [0,0.1,0.5, 1],
        transition: { duration: 0.4, ease: 'easeInOut', delay: 0.5 },
      })
    }
  };

  useEffect(() => {
    // setTimeout(() => {
      animateWords();
    // }, 1000)

    return () => {
      intialWordContainer.stop()
      finalWordContainer.stop()
    };
  }, []);
  return (
    <>
      <StepContainer>
        <StepContent
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
        >
          <Step1Top
            gap={
              screenWidth > maximumTabletWidth
                ? "10px"
                : (10 / screenWidth) * 100 + "vw"
            }
          >
            { // wordArr2.map((item, index) => (
              ["P", "A", "C", "E", "D"].map((item, index) => {
                return (
                  <NextBtn
                    variant="contained"
                    size="small"
                    height={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    width={
                      screenWidth > maximumTabletWidth
                        ? "60px"
                        : (60 / screenWidth) * 100 + "vw"
                    }
                    backgroundcolor={appColors.whiteColor}
                    // border={"1px solid"}
                    borderColor={appColors.borderColor}
                    // borderStyle={"dashed"}
                    boxshadow
                    cursor={'default'}
                  >
                    {
                      index == 0 ?
                        <>

                          <TextBtn
                            initial={{ opacity: 1 }}
                            animate={intialWordContainer}
                            color={appColors.greyTextColor}
                            fontSize={
                              screenWidth > maximumTabletWidth
                                ? "36px"
                                : (36 / screenWidth) * 100 + "vw"
                            }
                          >
                            {item}
                          </TextBtn>

                          <TextBtn
                            initial={{ opacity: 0 }}
                            animate={finalWordContainer}
                            color={appColors.greyTextColor}
                            fontSize={
                              screenWidth > maximumTabletWidth
                                ? "36px"
                                : (36 / screenWidth) * 100 + "vw"
                            }
                            positionabsolute
                          >
                            {"F"}
                          </TextBtn>
                        </> :
                       
                          <TextBtn
                            color={appColors.greyTextColor}
                            fontSize={
                              screenWidth > maximumTabletWidth
                                ? "36px"
                                : (36 / screenWidth) * 100 + "vw"
                            }
                          >
                            {item}
                          </TextBtn>
                    }

                  </NextBtn>
                )
              }
              )}
          </Step1Top>
        </StepContent>
      </StepContainer>
      <GameBtnContainer>
        <Step1Top
          gap={
            screenWidth > maximumTabletWidth
              ? "10px"
              : (10 / screenWidth) * 100 + "vw"
          }
        >
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Add
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Remove
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.greyBtnColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Change
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={
              screenWidth > maximumTabletWidth
                ? "37.5px"
                : (37.5 / screenWidth) * 100 + "vw"
            }
            width={
              screenWidth > maximumTabletWidth
                ? "80px"
                : (80 / screenWidth) * 100 + "vw"
            }
            backgroundcolor={appColors.whiteColor}
            borderradius={
              screenWidth > maximumTabletWidth
                ? "20px"
                : (20 / screenWidth) * 100 + "vw"
            }
            boxshadowactionbtn={true}
            cursor={'default'}
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={
                screenWidth > maximumTabletWidth
                  ? "12px"
                  : (12 / screenWidth) * 100 + "vw"
              }
              fontFamily={"poppins600"}
            >
              Mix Up
            </TextBtn>
          </NextBtn>
        </Step1Top>
      </GameBtnContainer>
    </>
  );
};

export default React.memo(Step5);
