import React, { useCallback, useEffect, useState } from 'react'
import { GameBtnContainer, LoadImg, MoveBtn, NextBtn, Step1Bottom, Step1Top, Step2Bottom, StepContainer, StepContent, TextBtn } from './styles'
import { maximumTabletWidth, screenWidth } from '../../utils'
import { appColors } from '../../themes/colorConst'
import { appImages } from '../../themes/imageConstant'
import { AnimatePresence, useAnimation, useMotionValue } from 'framer-motion'

export const Step6 = (props) => {

  const [text, setText] = useState(['F', 'A', 'C', 'E', 'D'])
  const initialText = useAnimation()
  const finalText = useAnimation()
  const buttonContainer = useAnimation()
  const actionBtnContainer = useAnimation()
  const opacityDuration = 1.5
  const xValue = 1; // Set your desired x value here
  const yValue = -1; // Set your desired x value here

  const [isVisible, setIsVisible] = useState(true);

  const texts = [['F', 'A', 'C', 'E', 'D'], ['D', 'E', 'C', 'A', 'F']]
  let textIndex = 0;


  const animateWords = useCallback(async () => {

    while (true) {
      // Wait for the animation to start
      await new Promise((resolve) => setTimeout(resolve, 1000));

      buttonContainer.mount()
      actionBtnContainer.mount()
      initialText.mount()
      finalText.mount()
      await Promise.all([
        initialText.start({
          opacity: [0.8, 0.6, 0.4, 0.2, 0],
          transition: { duration: 0.8, ease: 'easeInOut' },
        }),
        finalText.start({
          opacity: [0.2, 0.4, 0.6, 0.8, 1],
          transition: { duration: 0.8, ease: 'easeInOut' },
        })
      ]);

      await new Promise((resolve) => setTimeout(resolve, 500));

      initialText.mount()
      finalText.mount()
      await Promise.all([
        initialText.start({
          opacity: [0.2, 0.4, 0.6, 0.8, 1],
          transition: { duration: 0.8, ease: 'easeInOut' },
        }),
        finalText.start({
          opacity: [0.8, 0.6, 0.4, 0.2, 0],
          transition: { duration: 0.8, ease: 'easeInOut' },
        })
      ]);

    }

  }, [initialText, finalText])

  useEffect(() => {
    animateWords();

    return () => {
      initialText.stop();
      finalText.stop();
    };
  }, [animateWords])

  return (
    <>
      <StepContainer>
        <StepContent
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
        >
          <Step1Top
            gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >
            <AnimatePresence mode='wait'>
              {
                text.map((item, index) => {
                  return (
                    <MoveBtn
                      initial={{ opacity: 1, y: 0 }}
                      animate={buttonContainer}
                      contentCenter={"center"}
                    >
                      <NextBtn
                        variant="contained"
                        size="small"
                        height={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
                        width={screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw"}
                        backgroundcolor={appColors.whiteColor}
                        boxshadow
                        cursor={'default'}
                      >
                        {
                          <MoveBtn contentCenter>
                            <MoveBtn
                              initial={{ opacity: 1 }}
                              animate={initialText}
                              contentCenter={"center"}
                            >
                              <TextBtn
                                color={appColors.greyTextColor}
                                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
                              >

                                {item}
                              </TextBtn>
                            </MoveBtn>
                            <MoveBtn
                              initial={{ opacity: 0 }}
                              animate={finalText}
                              contentCenter={"center"}
                              positionabsolute
                            >
                              <TextBtn
                                color={appColors.greyTextColor}
                                fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
                              >

                                {
                                  index == 0
                                    ?
                                    "D"
                                    :
                                    index == 1
                                      ?
                                      "E"
                                      :
                                      index == 2
                                        ?
                                        "C"
                                        :
                                        index == 3
                                          ?
                                          "A"
                                          :
                                          "F"
                                }
                              </TextBtn>
                            </MoveBtn>
                          </MoveBtn>
                        }
                      </NextBtn>
                    </MoveBtn>
                  )
                })
              }
            </AnimatePresence>
          </Step1Top>

        </StepContent>

      </StepContainer>
      <GameBtnContainer>
        <MoveBtn
          initial={{ opacity: 1, y: 0 }}
          animate={actionBtnContainer}
          contentCenter={"center"}
        >
          <Step1Top
            gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          >

            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "37.5px" : (37.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "80px" : (80 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              borderradius={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"}
              boxshadowactionbtn={true}
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
                fontFamily={'poppins600'}
              >
                Add
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "37.5px" : (37.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "80px" : (80 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              borderradius={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"}
              boxshadowactionbtn={true}
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
                fontFamily={'poppins600'}
              >
                Remove
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "37.5px" : (37.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "80px" : (80 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.whiteColor}
              borderradius={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"}
              boxshadowactionbtn={true}
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
                fontFamily={'poppins600'}
              >
                Change
              </TextBtn>
            </NextBtn>
            <NextBtn
              variant="contained"
              size="small"
              height={screenWidth > maximumTabletWidth ? "37.5px" : (37.5 / screenWidth * 100) + "vw"}
              width={screenWidth > maximumTabletWidth ? "80px" : (80 / screenWidth * 100) + "vw"}
              backgroundcolor={appColors.greyBtnColor}
              borderradius={screenWidth > maximumTabletWidth ? "20px" : (20 / screenWidth * 100) + "vw"}
              boxshadowactionbtn={true}
              cursor={'default'}
            >
              <TextBtn
                color={appColors.greyTextColor}
                fontSize={screenWidth > maximumTabletWidth ? "12px" : (12 / screenWidth * 100) + "vw"}
                fontFamily={'poppins600'}
              >
                Mix Up
              </TextBtn>
            </NextBtn>
          </Step1Top>
        </MoveBtn>
      </GameBtnContainer>
    </>
  )
}

export default React.memo(Step6)
