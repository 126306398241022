import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { GenerateWord, LoadImg, MoveBtn, NextBtn, Step1Bottom, Step1Top, StepContainer, StepContent, TextBtn } from './styles'
import { getRandomImage, maximumTabletWidth, screenWidth } from '../../utils';
import { appColors } from '../../themes/colorConst';
import { appImages } from '../../themes/imageConstant';
import { useAnimation, motion } from 'framer-motion';

const GeneratingWord = ({ setBannerImage, wordLoader, words, setStatusBarColor, statusBarColor, bannerImageAnimate }) => {

  const rotatingImg = useAnimation()
  const generatingWord = useAnimation()

  const [rotation, setRotation] = useState(0);

  const moveX = 1
  const duration = 0.3
  const repeatDelay = 0.85

  const bannerImageForGenerateWord = [
    appImages.gameBanner1,
    appImages.gameBanner2,
    appImages.gameBanner3,
    appImages.gameBanner4,
    appImages.gameBanner5,
    appImages.gameBanner6,
    appImages.gameBanner7,
  ];

  // useLayoutEffect(() => {
  //   const metaThemeColor = document.querySelector('meta[name=theme-color]');
  //   if (metaThemeColor) {
  //     metaThemeColor.setAttribute('content', `${statusBarColor}`);
  //   }
  // }, [statusBarColor]);

  const animateImage = useCallback( async() => {
    rotatingImg.mount()
    rotatingImg.start({
      rotate: 360,
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 2,  // Adjust duration to control speed
        ease: 'linear',  // Use 'linear' for smooth continuous rotation
      },
    });

    while (true) {
      generatingWord.mount()
      await generatingWord.start({
        opacity: [0,0.1,0.3,0.6,1],
        transition: { duration: 0.5, ease: 'easeInOut'}
      });

      // let randomImage = getRandomImage(bannerImageForGenerateWord);

      // bannerImageAnimate.mount()
      // await bannerImageAnimate.start({
      //   background: `url(${randomImage?.randomImage})`
      // })
      
      // if (!words || wordLoader) {
      //   // setBannerImage(randomImage?.randomImage)
      //   setStatusBarColor(randomImage?.randomColor)
      // }
      generatingWord.mount()
      generatingWord.start({
        opacity: [1,0.6,0.3,0.1,0],
        transition: { duration: 0.5, ease: 'easeInOut', delay: 0.3}
      });
    }


  }, [])

  useEffect(() => {
    if (!words) {
      animateImage()
    } else {
      // rotatingImg?.stop();
      generatingWord.mount()
      generatingWord.start({
        opacity: 0,
      });
      generatingWord?.stop();
    }

  return () => {
    // rotatingImg?.stop();
    generatingWord?.stop();
  };
}, [words, wordLoader])

  return (
    // <div id="unknown">
    <StepContainer
      
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      cstmHeight={"100%"}
      positionabsolute
    >
      <StepContent
        gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
      >
        <Step1Top
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          initial={{ opacity: 1}}
          animate={words &&  { opacity: [1,0.6,0.3,0.1,0]}}
        >
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              C
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              H
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              A
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              N
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              G
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              E
            </TextBtn>
          </NextBtn>
        </Step1Top>
        <motion.div
            initial={{ opacity: 1}}
            animate={{ opacity: 1}}
            style={{ position: 'relative'}}
            // animate={words &&  { opacity: [1,0.6,0.3,0.1,0]}}
          >
          <NextBtn
            variant="contained"
            size="small"
            initial={{ 
              height: screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw",
              width: screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw",
              left: "150px",
              top: "10px",
              opacity: 1,
              zIndex: 99
            }}
            animate={words && {
              height: [
                screenWidth > maximumTabletWidth ? "52px" : (52 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "56px" : (56 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "64px" : (64 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "72px" : (72 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "82px" : (82 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "94px" : (94 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "108px" : (108 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "126px" : (126 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "144px" : (144 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "164px" : (164 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "186px" : (186 / screenWidth * 100) + "vw",
              ],
              width: [
                screenWidth > maximumTabletWidth ? "52px" : (52 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "56px" : (56 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "64px" : (64 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "72px" : (72 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "82px" : (82 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "94px" : (94 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "108px" : (108 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "126px" : (126 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "144px" : (144 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "164px" : (164 / screenWidth * 100) + "vw",
                screenWidth > maximumTabletWidth ? "186px" : (186 / screenWidth * 100) + "vw",
              ],
              left: ["150px", "149px", "147px", "144px", "139px", "132px", "128px", "120px", "108px", "95px", "80px"],
              top: ["10px", "9px", "7px", "3px", "-5px", "-10px", "-15px", "-22px", "-32px", "-45px", "-62px"],
              opacity: [1,0.9,0.8,0.7,0.6,0.5,0.4,0.3,0.2,0.1,0],
              transition: { duration: 1, ease: 'easeInOut'}
            }}
            // height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            // width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.greyTextColor}
            boxshadow
            positionabsolute
          >
               <MoveBtn
                height={"100%"}
                initial={{ rotate: 0, }}
                animate={rotatingImg}
          >
            <LoadImg
              initial={{ 
                height: screenWidth > maximumTabletWidth ? "35.5px" : (35.5 / screenWidth * 100) + "vw",
                width: screenWidth > maximumTabletWidth ? "38px" : (38 / screenWidth * 100) + "vw",
              }}
              animate={words && {
                height: [
                  screenWidth > maximumTabletWidth ? "40px" : (40 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "44px" : (44 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "58px" : (58 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "68px" : (68 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "80px" : (80 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "94px" : (94 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "110px" : (110 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "128px" : (128 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "148px" : (148 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "170px" : (170 / screenWidth * 100) + "vw",
                ],
                width: [
                  screenWidth > maximumTabletWidth ? "42px" : (42 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "46px" : (46 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "52px" : (52 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "60px" : (60 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "70px" : (70 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "82px" : (82 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "96px" : (96 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "112px" : (112 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "130px" : (130 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "150px" : (150 / screenWidth * 100) + "vw",
                  screenWidth > maximumTabletWidth ? "172px" : (172 / screenWidth * 100) + "vw",
                ],
                transition: { duration: 1}
              }}
              // transform={`rotate(${rotation}deg)`}
              src={appImages.loadIcon} 
            />
            </MoveBtn> 
          </NextBtn>
          </motion.div>
        <Step1Bottom
          gap={screenWidth > maximumTabletWidth ? "10px" : (10 / screenWidth * 100) + "vw"}
          initial={{ opacity: 1}}
          animate={words &&  { opacity: [1,0.6,0.3,0.1,0]}}
        >
          
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            // marginRight={marginRight}
            transform={`rotate(${rotation}deg)`}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              I
            </TextBtn>
          </NextBtn>
          <NextBtn
            variant="contained"
            size="small"
            height={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            width={screenWidth > maximumTabletWidth ? "50px" : (50 / screenWidth * 100) + "vw"}
            backgroundcolor={appColors.whiteColor}
            boxshadow
          >
            <TextBtn
              color={appColors.greyTextColor}
              fontSize={screenWidth > maximumTabletWidth ? "36px" : (36 / screenWidth * 100) + "vw"}
            >
              T
            </TextBtn>
          </NextBtn>
        </Step1Bottom>
        <GenerateWord
          initial={{ opacity: 0,}}
          animate={!words ? generatingWord : { opacity: 0}}
        >
          Generating Words...
        </GenerateWord>
      </StepContent>
      
    </StepContainer>
    // <div>
    
          // </div>
    // </div>
  )
}

export default GeneratingWord;