import React from 'react'
import { appImages } from '../../themes/imageConstant'
import { BannerImage } from './styles'

const BannerImg = (props) => {
  return (
    
        <BannerImage src={props.image} />
    
  )
}

export default BannerImg
