import { Method } from "./apiMethod";

//Auth
export const isUserExist = (data) => Method.post("/user/sign-up", data);
export const getQuestion = () => Method.get("/user/get-question");
export const completeOnboardingApi = (data) => Method.post("/user/complete-onboarding", data);
export const validateWord = (data) => Method.post("/user/validate-word", data);
export const resultApi = (data) => Method.post("/user/change-bot-count", data);
export const botStepsApi = (data) => Method.post("/user/change-bot-word", data);
export const timeLeftApi = (data) => Method.get("/user/get-time", data);
export const profileApi = (data) => Method.post("/user/user-detail", data);
export const userEventsClick = (data) => Method.post("/user/action-click", data);